import Decisions_en from '../en/pages/Decisions.json';
import CreateDecision_en from '../en/pages/CreateDecision.json';
import Signup_en from '../en/pages/Signup.json';
import HostedDecisions_en from "../en/pages/HostedDecisions.json"
import Participate_en from "../en/pages/Participate.json"
import ResetPassword_en from '../en/pages/ResetPassword';

import CreateDecisionForm_en from '../en/components/CreateDecisionForm';
import TemplatesList_en from '../en/components/TemplatesList';
import TemplateCard_en from '../en/components/TemplateCard';
import DecisionCopyTable_en from '../en/components/DecisionCopyTable';
import ChangePassword_en from '../en/components/ChangePassword.json';
import DeleteAccountModal_en from '../en/components/DeleteAccountModal.json';
import FilterTableColumnsModal_en from '../en/components/FilterTableColumnsModal.json';
import Header_en from '../en/components/Header.json';
import SuccessModal_en from '../en/components/SuccessModal.json';
import LoginWithMetamaskInstalled_en from '../en/components/LoginWithMetamaskInstalled.json';
import LoginWithoutMetamaskInstalled_en from '../en/components/LoginWithoutMetamaskInstalled.json';
import RecoverPasswordDone_en from '../en/components/RecoverPasswordDone.json';
import RecoverPasswordForm_en from '../en/components/RecoverPasswordForm.json';
import Sidebar_en from '../en/components/Sidebar.json';
import TableOfActionsHosting_en from '../en/components/TableOfActionsHosting.json';
import TableOfActionsInvitedToHost_en from '../en/components/TableOfActionsInvitedToHost.json';
import TableOfActionsInvitedToParticipate_en from '../en/components/TableOfActionsInvitedToParticipate.json';
import TableOfActionsParticipating_en from '../en/components/TableOfActionsParticipating.json';
import CreateDecisionModal_en from "../en/components/CreateDecisionModal.json";
import EditProfilePhoto_en from "../en/components/EditProfilePhoto.json";
import HubspotModal_en from '../en/components/HubspotModal.json';
import HostedDecisionHeader_en from "../en/components/HostedDecisionHeader.json"
import ParticipateHeader_en from "../en/components/ParticipateHeader.json"
import DecisionCard_en from "../en/components/DecisionCard.json"
import WalletHeader_en from "../en/components/WalletHeader.json"
import HomeDashboard_en from "../en/components/HomeDashboard.json"
import DecisionCardParticipate_en from "../en/components/DecisionCardParticipate.json"
import ModalDecisionCardParticipate_en from '../en/components/ModalDecisionCardParticipate.json';
import ModalDecisionCardHost_en from '../en/components/ModalDecisionCardHost.json';
import DecisionCardHost_en from '../en/components/DecisionCardHost.json';
import ModalCreateDecision_en from '../en/components/ModalCreateDecision';
import CreateDecisionHeader_en from '../en/components/CreateDecisionHeader';

const i18nextConfig = {
    interpolation: { escapeValue: false },
    lng: 'english',
    resources: {
        english: {
            ModalDecisionCardParticipate: ModalDecisionCardParticipate_en,
            TemplatesList: TemplatesList_en,
            Decisions: Decisions_en,
            CreateDecision: CreateDecision_en,
            CreateDecisionForm: CreateDecisionForm_en,
            Signup: Signup_en,
            DecisionCopyTable: DecisionCopyTable_en,
            ChangePassword: ChangePassword_en,
            DeleteAccountModal: DeleteAccountModal_en,
            FilterTableColumnsModal: FilterTableColumnsModal_en,
            Header: Header_en,
            LoginWithMetamaskInstalled: LoginWithMetamaskInstalled_en,
            LoginWithoutMetamaskInstalled: LoginWithoutMetamaskInstalled_en,
            RecoverPasswordDone: RecoverPasswordDone_en,
            RecoverPasswordForm: RecoverPasswordForm_en,
            Sidebar: Sidebar_en,
            TableOfActionsHosting: TableOfActionsHosting_en,
            TableOfActionsInvitedToHost: TableOfActionsInvitedToHost_en,
            TableOfActionsInvitedToParticipate: TableOfActionsInvitedToParticipate_en,
            TableOfActionsParticipating: TableOfActionsParticipating_en,
            TemplateCard: TemplateCard_en,
            CreateDecisionModal: CreateDecisionModal_en,
            EditProfilePhoto: EditProfilePhoto_en,
            HubspotModal: HubspotModal_en,
            SuccessModal: SuccessModal_en,
            HostedDecisionHeader: HostedDecisionHeader_en,
            ParticipateHeader: ParticipateHeader_en,
            DecisionCard: DecisionCard_en,
            HostedDecisions: HostedDecisions_en,
            Participate: Participate_en,
            WalletHeader: WalletHeader_en,
            HomeDashboard: HomeDashboard_en,
            DecisionCardParticipate: DecisionCardParticipate_en,
            DecisionCardHost: DecisionCardHost_en,
            ModalDecisionCardHost:ModalDecisionCardHost_en,
            ModalCreateDecision: ModalCreateDecision_en,
            CreateDecisionHeader: CreateDecisionHeader_en,
            ResetPassword: ResetPassword_en
        }
    }
};

export default i18nextConfig;

