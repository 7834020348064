import React from 'react';
import { Flex, Spinner, Box, Text } from '@chakra-ui/react';
import "./styles.css"

export default function LoadingScreen({ loading }) {

    return (
        <>
            {loading && (
                <Box className='box-loading-screen'>
                    <Flex className='flex-loading-screen'>
                        <Spinner className='spinner-loading-screen' size="xl" />
                        <Text className='text-loading-screen'>Loading...</Text>
                    </Flex>
                </Box>
            )}
        </>
    );
}
