import { useState, useEffect } from "react";
import {
  Flex,
  Center,
  Button,
  Stack,
  Box,
  Grid,
  Image,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./styles.css";

const RecoverPasswordDone = () => {
  const [t] = useTranslation("RecoverPasswordDone");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Flex className="main-flex-recover-password-done" height="100vh">
      <Grid className="grid-recover-password-done">
        {windowWidth > 768 && (
          <Image
            src="polity_side.png"
            className="side-image-recover-password-done"
            minHeight="100vh"
            maxHeight="100vh"
          />
        )}
        <Stack
          className="main-stack-recover-password-done"
          minHeight="100vh"
          maxHeight="100vh"
          justify="center"
        >
          <Box className="forgot-text-recover-password-done">
            {t("forgot-your-password")}
          </Box>
          <Box className="reset-text-recover-password-done">
            {t("reset-password")}
          </Box>
          <Box className="main-box-recover-password-done">
            <Center>
              <Button
                className="login-button-recover-password-done"
                as={RouterLink}
                to="/"
              >
                {t("please-sign-in")}
              </Button>
            </Center>
          </Box>
        </Stack>
      </Grid>
    </Flex>
  );
};

export default RecoverPasswordDone;
