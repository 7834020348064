import React, { useState } from "react";
import {
  Card,
  Box,
  Button,
  Text,
  Flex,
  Link,
  useDisclosure,
  Image,
  Tooltip,
} from "@chakra-ui/react";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { timeDifference, formatDate } from "utils";
import ModalInviteParticipants from "components/ModalInviteParticipants";
import standardImage from "assets/images/standard_image.png";

const DecisionCardHost = ({ currentDecision }) => {
  const [t] = useTranslation("DecisionCardHost");
  const [isError, setIsError] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  return (
    <>
      <ModalInviteParticipants
        isOpen={isOpen}
        onClose={onClose}
        currentDecision={currentDecision}
      />
      <Card className="card-decision-card-host">
        <Box className="box-image-decision-card-host">
          <Image
            className="image-decision-card-host"
            src={
              isError
                ? standardImage
                : currentDecision["image-url"] || standardImage
            }
            alt={"Decision"}
            onError={() => setIsError(true)}
          />
        </Box>
        <Text className="title-decision-card-host">
          {currentDecision["title"]}
        </Text>
        <Link
          href={
            currentDecision["current-mode"] === "Unpublished"
              ? currentDecision?.links?.preview
              : currentDecision?.links?.visit
          }
          target="_blank"
        >
          {currentDecision["current-mode"] === "Unpublished"
            ? currentDecision?.links?.preview
            : currentDecision?.links?.visit}
        </Link>
        {currentDecision.invited && (
          <Link href={currentDecision?.links?.visit}>
            <Button className="special-link-button-decision-card-host">
              <FontAwesomeIcon
                icon="fa-regular fa-envelope"
                className="icon-button-decision-card-host"
              />
              {t("accept-invitation")}
            </Button>
          </Link>
        )}

        {!currentDecision.invited &&
          currentDecision["current-mode"] === "Open" && (
            <Link href={currentDecision?.links?.visit}>
              <Button className="special-link-button-decision-card-host">
                <FontAwesomeIcon
                  icon="fa-regular fa-moon"
                  className="icon-button-decision-card-host"
                />
                {currentDecision.completed ? currentDecision.completed : 0}%{" "}
                {t("completed")}
              </Button>
            </Link>
          )}

        {!currentDecision.invited &&
          currentDecision["current-mode"].includes("Finished") && (
            <Link href={currentDecision?.links?.results}>
              <Button className="special-link-button-decision-card-host">
                <FontAwesomeIcon
                  icon="fa-regular fa-check"
                  className="icon-button-decision-card-host"
                />
                {t("view-results")}
              </Button>
            </Link>
          )}

        {!currentDecision.invited &&
          currentDecision["current-mode"].includes("Unpublished") && (
            <Link href={currentDecision?.links?.results}>
              <Button className="special-link-button-decision-card-host">
                <FontAwesomeIcon
                  icon="fa-regular fa-moon"
                  className="icon-button-decision-card-host"
                />
                {t("unpublished")}
              </Button>
            </Link>
          )}
        <Flex direction="column">
          <Flex className="date-flex-decision-card-host">
            <Text className="date-tag-decision-card-host">
              {t("opens-on")}:
            </Text>
            <Text ml={0} className="date-text-decision-card-host">
              {currentDecision["open-at"] ? (
                <>
                  {formatDate(currentDecision["open-at"])} (
                  {timeDifference(currentDecision["open-at"])})
                </>
              ) : (
                <>{t("tbd")}</>
              )}
            </Text>
          </Flex>
          <Flex className="date-flex-decision-card-host">
            <Text className="date-tag-decision-card-host">{t("closes")}:</Text>
            <Text ml={0} className="date-text-decision-card-host">
              {currentDecision["close-at"] ? (
                <>
                  {formatDate(currentDecision["close-at"])} (
                  {timeDifference(currentDecision["close-at"])})
                </>
              ) : (
                <>{t("tbd")}</>
              )}
            </Text>
          </Flex>
        </Flex>

        <Flex
          className="flex-buttons-decision-card-host"
          gap={2}
          direction="row"
        >
          {currentDecision?.links?.visit && (
            <Tooltip label={t("visit")}>
              <Link
                href={currentDecision?.links?.visit}
                target="_blank"
                width="100%"
              >
                <Button className="icon-bottom-button-decision-card-host">
                  <FontAwesomeIcon
                    icon="fa-solid fa-eye"
                    className="icon-decision-card-host"
                  />
                </Button>
              </Link>
            </Tooltip>
          )}
          {currentDecision?.links?.edit && (
            <Tooltip label={t("edit")}>
              <Link
                href={currentDecision?.links?.edit}
                target="_blank"
                width="100%"
              >
                <Button className="icon-bottom-button-decision-card-host">
                  <FontAwesomeIcon
                    icon="fa-solid fa-pen"
                    className="icon-decision-card-host"
                  />
                </Button>
              </Link>
            </Tooltip>
          )}
          {currentDecision?.links?.analysis && (
            <Tooltip label={t("analysis")}>
              <Link
                href={currentDecision?.links?.analysis}
                target="_blank"
                width="100%"
              >
                <Button className="icon-bottom-button-decision-card-host">
                  <FontAwesomeIcon
                    icon="fa-solid fa-chart-simple"
                    className="icon-decision-card-host"
                  />
                </Button>
              </Link>
            </Tooltip>
          )}

          <Tooltip label={t("invite")}>
            <Link width="100%" onClick={() => onOpen()}>
              <Button className="icon-bottom-button-decision-card-host">
                <FontAwesomeIcon
                  icon="fa-solid fa-user-plus"
                  className="icon-decision-card-host"
                />
              </Button>
            </Link>
          </Tooltip>

          {currentDecision?.links?.comments && (
            <Tooltip label={t("comments")}>
              <Link
                href={currentDecision?.links?.comments}
                target="_blank"
                width="100%"
              >
                <Button className="icon-bottom-button-decision-card-host">
                  <FontAwesomeIcon
                    icon="fa-solid fa-comment"
                    className="icon-decision-card-host"
                  />
                </Button>
              </Link>
            </Tooltip>
          )}
        </Flex>

        <Button
          className="button-details-decision-card-host"
          rightIcon={<FontAwesomeIcon icon="fa-solid fa-chevron-down" />}
          justifyContent="space-between"
          onClick={() =>
            navigate(`/current-decision/${currentDecision["decision-id"]}`)
          }
        >
          {t("show-details")}
        </Button>
      </Card>
    </>
  );
};

export default DecisionCardHost;
