import React from "react";
import {
  Box,
  Image,
  Text,
  Button,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import HTMLReactParser from "html-react-parser";
import { useCurrentDecisionsContext } from "features/decisions/contexts/decisions-provider";
import { useTranslation } from "react-i18next";
import "./styles.css";
import ModalCreateDecision from "../ModalCreateDecision";

export default function TemplateCard({ decisionTemplate }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { title, image, info, links } = decisionTemplate;
  const navigate = useNavigate();
  const { setCreateDecisionData } = useCurrentDecisionsContext();
  const [t] = useTranslation("TemplateCard");

  const goToCreateFormRoute = (data) => {
    setCreateDecisionData(data, true);
    navigate("/create-decision-form", { replace: true });
  };

  const handleVisitClick = () => {
    const url = links.visit ? links.visit : links.self ? links.self : "";
    if (url) {
      window.open(url, "_blank");
    }
  };

  return (
    <>
      <ModalCreateDecision isOpen={isOpen} onClose={onClose} decisionData={decisionTemplate}/>
      <Box
        className="main-box-template-card"
        role="region"
        aria-label="Template card"
      >
        <Image
          src={image || "standar_image.png"}
          alt="decision template"
          className="image-template-card"
        />
        <Text
          className="text-title-template-card"
          role="heading"
          aria-level="1"
        >
          {title}
        </Text>
        <Text className="html-text-template-card" role="heading" aria-level="2">
          {HTMLReactParser(info)}
        </Text>
        <Flex className="flex-template-card" gap={8}>
          <Button
            onClick={() =>onOpen() /* goToCreateFormRoute(decisionTemplate)*/}
            className="first-button-template-card"
            flex="1"
          >
            {" "}
            {t("create")}
          </Button>
          <Button
            onClick={() => handleVisitClick()}
            className="second-button-template-card"
            flex="1"
          >
            {t("demo")}
          </Button>
        </Flex>
      </Box>
    </>
  );
}
