import { Box } from "@chakra-ui/react";
import DeleteAccountModal from "components/DeleteAccountModal";
import EditProfilePhoto from "components/EditProfilePhoto";
import AccountHeader from "components/AccountHeader";
import ChangePassword from "components/ChangePassword";
import "./styles.css";

const Account = ({ lightMode }) => {
  return (
    <>
      <Box className="main-box-account" id="main">
        <AccountHeader />
        <ChangePassword />
      </Box>
    </>
  );
};

export default Account;
