import React, { useState } from "react";
import { Card, Flex, Box, Text, Button, useDisclosure } from "@chakra-ui/react";
import PaymentItems from "components/PaymentItems";
import "./styles.css";

export default function WalletBody() {



    return (
        <>
        <Card className='card-main-wallet-body' variant="outline">
            <Text className='product-head-wallet-body'>
                Products
            </Text>
            <Text className='product-body-wallet-body'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
            </Text>
            {/*<Flex direction="row" gap={8} py={4}>
                <Card flex="1" className='card-form-wallet-body'>
                    <Text className='payment-text-wallet-body'>Membership token</Text>
                    <Flex>
                        <Text className='payment-amount-wallet-body'>
                            $100
                        </Text>
                        <Text className='single-payment-wallet-body'>
                            single payment
                        </Text>
                    </Flex>
                    <Box p={4}>
                        <Flex alignItems="flex-start" mb={2}>
                            <Box
                                w="15px"
                                h="10px"
                                borderRadius="50%"
                                bg="blue.500"
                                mr={2}
                                position="relative"
                                top="6.5px"
                                className='circle-wallet-body'
                            />
                            <Text className='text-circle-wallet-body'>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </Text>
                        </Flex>
                        <Flex alignItems="center" mb={2}>
                            <Box
                                w="10px"
                                h="10px"
                                borderRadius="50%"
                                bg="blue.500"
                                mr={2}
                                position="relative"
                                top="-8px"
                                className='circle-wallet-body'
                            />
                            <Text className='text-circle-wallet-body'>
                                When an unknown printer took.
                            </Text>
                        </Flex>
                        <Flex alignItems="center">
                            <Box
                                w="10px"
                                h="10px"
                                borderRadius="50%"
                                bg="blue.500"
                                mr={2}
                                position="relative"
                                top="-8px"
                                className='circle-wallet-body'
                            />
                            <Text className='text-circle-wallet-body'>
                                1500s scrambled
                            </Text>
                        </Flex>
                        <Button className='payment-button-wallet-body' onClick={()=>openPayment("Membership token","subscription",100)}>
                            Checkout with stripe
                        </Button>
                    </Box>
                </Card>
                <Card flex="1" className='card-form-wallet-body'>
                    <Text className='payment-text-wallet-body'>Investment Share</Text>
                    <Flex>
                        <Text className='payment-amount-wallet-body'>
                            $10
                        </Text>
                        <Text className='single-payment-wallet-body'>
                            single payment
                        </Text>
                    </Flex>
                    <Box p={4}>
                        <Flex alignItems="flex-start" mb={2}>
                            <Box
                                w="15px"
                                h="10px"
                                borderRadius="50%"
                                bg="blue.500"
                                mr={2}
                                position="relative"
                                top="6.5px"
                                className='circle-wallet-body'
                            />
                            <Text className='text-circle-wallet-body'>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </Text>
                        </Flex>
                        <Flex alignItems="center" mb={2}>
                            <Box
                                w="10px"
                                h="10px"
                                borderRadius="50%"
                                bg="blue.500"
                                mr={2}
                                position="relative"
                                top="-8px"
                                className='circle-wallet-body'
                            />
                            <Text className='text-circle-wallet-body'>
                                When an unknown printer took.
                            </Text>
                        </Flex>
                        <Flex alignItems="center">
                            <Box
                                w="10px"
                                h="10px"
                                borderRadius="50%"
                                bg="blue.500"
                                mr={2}
                                position="relative"
                                top="-8px"
                                className='circle-wallet-body'
                            />
                            <Text className='text-circle-wallet-body'>
                                1500s scrambled
                            </Text>
                        </Flex>
                        <Button className='payment-button-wallet-body' onClick={()=>openPayment("Investment Share","tokens",10)}>
                            Checkout with stripe
                        </Button>
                    </Box>
                </Card>
            </Flex>*/}
            <PaymentItems />
        </Card>
        </>
    );
}
