import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Box, useDisclosure, ChakraProvider } from "@chakra-ui/react";
import Header from "./components/Header";
import { useCurrentUserContext } from "./features/user/contexts/user-provider";
import SidebarLayout from "./components/SidebarLayout";
import LoadingScreen from "./components/LoadingScreen";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import RecoverPassword from "./pages/RecoverPassword";
import Account from "./pages/Account";
import Participate from "pages/Participate";
import HostedDecisions from "pages/HostedDecisions";
import CreateDecision from "./pages/CreateDecision";
import CreateDecisionFormPage from "./pages/CreateDecisionFormPage";
import Wallet from "./pages/Wallet";
import HomeDashboard from "./pages/HomeDashboard";
import TableOfCurrentDecision from "./pages/TableOfCurrentDecision";
import ResetPassword from "./pages/ResetPassword";
import EditGroup from "./pages/EditGroup";
import Group from "./pages/Group";
import GroupHome from "./pages/GroupHome";
import { useCurrentDecisionsContext } from "./features/decisions/contexts/decisions-provider";
import { useCurrentPaymentsContext } from "./features/payments/contexts/payments-provider";
import { theme, darkTheme } from "./theme";
import "./App.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

const App = () => {
  const { authorized, isLoaded, user } = useCurrentUserContext();
  const { getDataOfDecisions, dataOfDecisionsAlreadyCalled } =
    useCurrentDecisionsContext();
  const { fetchPayments } = useCurrentPaymentsContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [lightMode, setLightMode] = useState(true);
  let isHost = true;

  const changeBetweenLightAndDarkMode = () => {
    setLightMode((prevLightMode) => {
      const newLightMode = !prevLightMode;
      localStorage.setItem("lightMode", newLightMode);
      return newLightMode;
    });
  };

  const concatenateDecisions = (actives, invitations) => {
    const decisionsActives = actives.map((active) => ({
      ...active,
      invited: false,
    }));
    const decisionsInvitations = invitations.map((invitation) => ({
      ...invitation,
      invited: true,
    }));

    return decisionsActives.concat(decisionsInvitations);
  };

  const location = useLocation();

  useEffect(() => {
    const baseTitle = "Polity - ";

    const routeToTitle = {
      "/": authorized ? "Home" : "Login",
      "/participate": "Participate Decisions",
      "/hosted-decisions": "Hosted Decisions",
      "/switch-group": "Switch Group",
      "/account": "Account",
      "/signup": "Sign up",
      "/recover-password": "Recover Password",
      "/create-decisions": "Create Decisions",
      "/create-decision-form": "Create Decisions Form",
      "/wallet": "Wallet",
      "/reset-password": "Reset Password",
      "/group": "Group",
      "/edit-group": "Edit Group",
      "/group": "Group",
      "/group-home": "Group Home"
    };

    // Define a regex for dynamic routes
    const dynamicRouteRegexCurrentDecision = /^\/current-decision\/(.+)$/;
    const currentDecisionMatch = location.pathname.match(
      dynamicRouteRegexCurrentDecision
    );

    const dynamicRouteRegexGroup = /^\/group\/(.+)$/;
    const groupMatch = location.pathname.match(
      dynamicRouteRegexGroup
    ); 
    
    const dynamicRouteRegexGroupHome = /^\/group-home\/(.+)$/;
    const groupHomeMatch = location.pathname.match(
      dynamicRouteRegexGroupHome
    );     

    let currentTitle;

    if (currentDecisionMatch) {
      // If the path matches the dynamic route
      currentTitle = "Current Decision";
    } else if(groupHomeMatch){
      currentTitle = "Group Home";

    } else if(groupMatch){
      currentTitle = "Group";
    }
    
    else {
      currentTitle = routeToTitle[location.pathname] || "Not found";
    }

    document.title = baseTitle + currentTitle;
  }, [location.pathname, authorized]);

  useEffect(() => {
    if (authorized) {
      getDataOfDecisions();
      if (user && user.id) fetchPayments(user.id);
    }
  }, [authorized]);

  useEffect(() => {
    const savedLightMode = localStorage.getItem("lightMode");
    if (savedLightMode) {
      setLightMode(savedLightMode === "true");
    }
  }, []);

  useEffect(() => {
    const backgroundColor = authorized
      ? "var(--SECONDARY-GREY)"
      : "var(--CLEAR-BACKGROUND)";
    document.body.style.setProperty("background-color", backgroundColor);
  }, [authorized]);

  const handleDataTheme = (isLightMode) => {
    if (isLightMode) {
      document.body.removeAttribute("data-theme");
    } else {
      document.body.setAttribute("data-theme", "dark");
    }
  };

  useEffect(() => {
    handleDataTheme(lightMode);
  }, [lightMode]);

  return (
    <ChakraProvider theme={lightMode ? theme : darkTheme}>
      {authorized && (
        <Box className="app-box-header">
          <Header
            onOpen={onOpen}
            onClose={onClose}
            isOpen={isOpen}
            changeBetweenLightAndDarkMode={changeBetweenLightAndDarkMode}
            lightMode={lightMode}
          />
        </Box>
      )}
      <Box className={authorized ? "app-main-box-logged" : "app-main-box"}>
        <Routes>
          {!isLoaded && (
            <Route
              path="*"
              element={<LoadingScreen loading={true} lightMode={lightMode} />}
            />
          )}
          {isLoaded && authorized && (
            <Route
              path="/"
              element={
                <SidebarLayout
                  isOpen={isOpen}
                  onClose={onClose}
                  changeBetweenLightAndDarkMode={changeBetweenLightAndDarkMode}
                  lightMode={lightMode}
                />
              }
            >
              {!dataOfDecisionsAlreadyCalled && (
                <Route path="/" element={<LoadingScreen loading={true} />} />
              )}
              <Route path="/" element={<HomeDashboard />} />
              {!isHost &&  !dataOfDecisionsAlreadyCalled && (
                <Route
                  path="/participate"
                  element={<LoadingScreen loading={true} />}
                />
              )}
              {!isHost && <Route
                path="/participate"
                element={
                  <Participate
                    lightMode={lightMode}
                    concatenateDecisions={concatenateDecisions}
                  />
                }
              />}
              {isHost && !dataOfDecisionsAlreadyCalled && (
                <Route
                  path="/hosted-decisions"
                  element={<LoadingScreen loading={true} />}
                />
              )}
              {isHost && <Route
                path="/hosted-decisions"
                element={
                  <HostedDecisions
                    lightMode={lightMode}
                    concatenateDecisions={concatenateDecisions}
                  />
                }
              />}
              <Route
                path="/account"
                element={<Account lightMode={lightMode} />}
              />
              {isHost && !dataOfDecisionsAlreadyCalled && (
                <Route
                  path="/create-decisions"
                  element={<LoadingScreen loading={true} />}
                />
              )}
              {isHost && <Route
                path="/create-decisions"
                element={<CreateDecision lightMode={lightMode} />}
              />}
              {isHost && <Route path="/edit-group" element={<EditGroup />} />}
              <Route path="/group/:id" element={<Group />} />
              <Route path="/group-home/:id" element={<GroupHome />} />
              <Route
                path="/create-decision-form"
                element={<CreateDecisionFormPage />}
              />
              <Route path="/wallet" element={<Wallet />} />
              {isHost &&<Route
                path="/current-decision/:id"
                element={<TableOfCurrentDecision />}
              />}
            </Route>
          )}
          {isLoaded && !authorized && (
            <Route path="/signup" element={<Signup />} />
          )}
          {isLoaded && !authorized && (
            <Route path="/recover-password" element={<RecoverPassword />} />
          )}
          {isLoaded && !authorized && (
            <Route path="/reset-password" element={<ResetPassword />} />
          )}
          {isLoaded && !authorized && <Route path="/" element={<Login />} />}
          {isLoaded && <Route path="*" element={<Navigate to="/" />} />}
        </Routes>
      </Box>
    </ChakraProvider>
  );
};

export default App;
library.add(fab, fas, far);
