import React, { useState } from 'react';
import { Button, Text, Card, Flex, Avatar } from '@chakra-ui/react';
import { useCurrentUserContext } from 'features/user/contexts/user-provider';
import { useTranslation } from 'react-i18next';
import "./styles.css"

const EditProfilePhoto = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [isImageUploaded, setIsImageUploaded] = useState(false);
    const { user } = useCurrentUserContext();
    const [t] = useTranslation('EditProfilePhoto');

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
        setIsImageUploaded(true);
    };

    const handleRemoveImage = () => {
        setSelectedImage(null);
        setIsImageUploaded(false);
    };

    const handleUpload = () => {
        if (selectedImage) {
            console.log('Selected image:', selectedImage);
        } else {
            console.log('No image.');
        }
    };

    return (
        <Card className='card-edit-profile-photo' role="region" aria-label="Handle image">
            <Text className='label-text-edit-profile-photo' role="heading" aria-level="1">
                {t("change-profile-photo")}
            </Text>
            <Flex className='main-flex-edit-profile-photo' >
                <Avatar
                    src={user && user.avatar ? user.avatar : null}
                    className='avatar-edit-profile-photo'
                    alt="user"
                />
                <Button className='remove-image-button-edit-profile-photo' _disabled={{ bgColor: "var(--clearButtonBackground)" }} isDisabled={!selectedImage} onClick={handleRemoveImage}>
                    {t("remove-image")}
                </Button>
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    className='image-handler-input-edit-profile-photo'
                    id="fileInput"
                />
                {!isImageUploaded ? (
                    <label htmlFor="fileInput" className='label-edit-profile-photo'>
                        <Button as="span" className='send-image-button-edit-profile-photo'>
                            {t("add-image")}
                        </Button>
                    </label>
                ) : (
                    <Button as="span" className='send-image-button-edit-profile-photo' onClick={handleUpload}>
                        {t("send-image")}
                    </Button>
                )}
            </Flex>
        </Card>
    );
}

export default EditProfilePhoto;