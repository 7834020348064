import React from 'react'
import {
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Button
} from "@chakra-ui/react"
import { useTranslation } from 'react-i18next';
import "./styles.css"

const SuccessModal = ({ isOpen, onClose, text }) => {
  const [t] = useTranslation('SuccessModal');
  return (
    <Modal isOpen={isOpen} onClose={onClose} >
      <ModalOverlay />
      <ModalContent className='modal-content-success-modal' size="sm">
        <ModalBody>
          <Image src="success.png" className='success-images-success-modal' alt="success" />
          <Text role="heading" aria-level="1">
            {text}
          </Text>
          <Button className='button-success-modal' onClick={onClose}>
            {t("close")}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default SuccessModal
