import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Flex,
  Stack,
  CardBody,
  Text,
  Button,
  Avatar,
  Image,
} from "@chakra-ui/react";
import "./styles.css";
import { useCurrentUserContext } from "features/user/contexts/user-provider";
import { useTranslation } from "react-i18next";
import PaymentItems from "components/PaymentItems";

export default function HomeDashboard() {
  const { user } = useCurrentUserContext();
  const [t] = useTranslation("HomeDashboard");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
      const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  return (
    <>
      <Box className="main-box-home-dashboard">
        <Card className="card-header-home-dashboard" variant="outline">
          <Flex className="flex-center-header-home-dashboard">
            <Avatar
              src={user.avatar}
              className="avatar-header-home-dashboard"
            />
            <Stack
              className="stack-header-home-dashboard"
              role="main"
              aria-label="Create decision card"
            >
              <CardBody className="card-body-header-home-dashboard">
                <Text className="main-text-header-home-dashboard">
                  {t("welcome-back")}{windowWidth > 768 && ","}{" "}
                  <span className="name-text-header-home-dashboard">
                    {user.name}
                  </span>
                </Text>
                {windowWidth > 768 && <Text className="secondary-text-header-home-dashboard">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </Text>}
              </CardBody>
            </Stack>
          </Flex>
        </Card>
        <Card className="info-card-home-dashboard">
          <Text className="info-text-header-home-dashboard">
            {t("quick-guide")}
          </Text>
          <Text className="info-text-body-home-dashboard">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </Text>
          <Card className="secondary-info-card-home-dashboard">
            <Text className="secondary-info-card-header-text-home-dashboard">
              {t("participate")}
            </Text>
            <Text className="secondary-info-card-body-text-home-dashboard">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </Text>
          </Card>
          <Card className="secondary-info-card-home-dashboard">
            <Text className="secondary-info-card-header-text-home-dashboard">
              {t("host")}
            </Text>
            <Text className="secondary-info-card-body-text-home-dashboard">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </Text>
          </Card>
          <Card className="secondary-info-card-home-dashboard">
            <Text className="secondary-info-card-header-text-home-dashboard">
              {t("create-a-decision")}
            </Text>
            <Text className="secondary-info-card-body-text-home-dashboard">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </Text>
          </Card>
        </Card>
        <Card className="products-card-home-dashboard">
          <Text className="products-card-header-text-home-dashboard">
            {t("products")}
          </Text>
          <Text className="products-card-body-text-home-dashboard">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s.
          </Text>
          <PaymentItems />
        </Card>
        <Card className="bottom-card-home-dashboard">
          <Text className="bottom-card-header-text-home-dashboard">
            {t("latests-updates")}
          </Text>
          <Text className="bottom-card-body-text-home-dashboard">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </Text>
          <Flex
            direction={{ base: "column", md: "row" }}
            gap={8}
            wrap="wrap"
            className="flex-bottom-cards-home-dashboard"
          >
            <Card
              flex={{ base: "initial", md: "1" }}
              className="card-bottom-home-dashboard"
            >
              <Box className="box-image-home-dashboard">
                <Image src="standar_image.png" alt="news" width="100%" />
                <Button className="date-button-home-dashboard">
                  Oct. 15 2024
                </Button>
              </Box>
              <Text className="card-bottom-header-text-home-dashboard">
                {t("update")}
              </Text>
              <Text className="card-bottom-body-text-home-dashboard">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </Text>
            </Card>

            <Card
              flex={{ base: "initial", md: "1" }}
              className="card-bottom-home-dashboard"
            >
              <Box className="box-image-home-dashboard">
                <Image src="standar_image.png" alt="news" width="100%" />
                <Button className="date-button-home-dashboard">
                  Oct. 15 2024
                </Button>
              </Box>
              <Text className="card-bottom-header-text-home-dashboard">
                {t("update")}
              </Text>
              <Text className="card-bottom-body-text-home-dashboard">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </Text>
            </Card>

            <Card
              flex={{ base: "initial", md: "1" }}
              className="card-bottom-home-dashboard"
            >
              <Box className="box-image-home-dashboard">
                <Image src="standar_image.png" alt="news" width="100%" />
                <Button className="date-button-home-dashboard">
                  Oct. 15 2024
                </Button>
              </Box>
              <Text className="card-bottom-header-text-home-dashboard">
                {t("update")}
              </Text>
              <Text className="card-bottom-body-text-home-dashboard">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </Text>
            </Card>
          </Flex>
        </Card>
      </Box>
    </>
  );
}
