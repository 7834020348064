import {
  Flex,
  Input,
  Button,
  InputGroup,
  CardBody,
  Text,
  Card,
  Link
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom"
import { chakraStyles, selectWithSearchStyles } from "./selectStyles"
import { Select as SelectWithSearch } from "chakra-react-select";
import { useEffect, useState } from "react"
import { getNames, getCode, getCodes, getName } from "country-list";
import moment from "moment-timezone"
import { useCurrentDecisionsContext } from 'features/decisions/contexts/decisions-provider';
import { useTranslation } from 'react-i18next';
import decisionApi from "api/decisions"
import { getErrorMessage } from "utils"
import CreateDecisionModal from "components/CreateDecisionModal";
import LeftArrow from 'images/leftArrow'
import LoadingScreen from "components/LoadingScreen";
import "./styles.css"

const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
}

const hasHTML = (str) => {
  const htmlRegex = /<[a-z][\s\S]*>/;

  return htmlRegex.test(str);
}

const timezones = moment.tz.names();

const getTimezonesInFormat = () => {
  return timezones
    .map((timezone) => {
      const currentTime = moment.tz(timezone);
      const offsetHours = currentTime.utcOffset() / 60;
      return { timezone, offsetHours };
    })
    .sort((a, b) => a.offsetHours - b.offsetHours)
    .map(({ timezone, offsetHours }) => (
      {
        value: timezone,
        label: `(GMT${offsetHours >= 0 ? `+${offsetHours}` : offsetHours}) ${timezone}`
      }
    ));
}

const getCountryInFormat = country => {
  return {
    label: country,
    value: getCode(country)
  }
}

const countries = getNames().map((name) => (getCountryInFormat(name)));



const getTimezoneObject = timezone => {
  const currentTime = moment.tz(timezone);
  const offsetHours = currentTime.utcOffset() / 60
  return {
    value: timezone,
    label: `(GMT${offsetHours >= 0 ? `+${offsetHours}` : offsetHours}) ${timezone}`
  }
}

const getCurrentDateInISO8601Format = () => {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

  return formattedDate;
};

const CreateDecisionForm = () => {
  const navigate = useNavigate();
  const { createDecisionData } = useCurrentDecisionsContext()
  const [openModal, setOpenModal] = useState(false)
  const { decisionData } = createDecisionData
  const [t] = useTranslation('CreateDecisionForm');
  const [editDecisionLink, setEditDecisionLink] = useState("")
  const [subdomain, setSubdomain] = useState("")
  const [title, setTitle] = useState(decisionData?.title ? decisionData.title : "")
  const [country, setCountry] = useState(decisionData?.country ? getCountryInFormat(getName(decisionData.country)) : getCountryInFormat(getName("CA")))
  const [timezone, setTimezone] = useState(getTimezoneObject("UTC"))
  const [openAt, setOpenAt] = useState(getCurrentDateInISO8601Format())
  const [creatingDecision, setCreatingDecision] = useState(false)
  const [errorWithTitle, setErrorWithTitle] = useState(false)
  const [errorWithTitleText, setErrorWithTitleText] = useState("")
  const [errorWithSubdomain, setErrorWithSubdomain] = useState(false)
  const [errorWithSubdomainText, setErrorWithSubdomainText] = useState("")
  const [errorWithTimezone, setErrorWithTimezone] = useState(false)
  const [errorWithTimezoneText, setErrorWithTimezoneText] = useState("")
  const [errorWithCountry, setErrorWithCountry] = useState(false)
  const [errorWithCountryText, setErrorWithCountryText] = useState("")
  const [loading, setLoading] = useState(false)
  const timezonesToDisplay = getTimezonesInFormat()


  const createDecision = () => {
    let errorFound = false

    setErrorWithCountry(false)
    setErrorWithSubdomain(false)
    setErrorWithTitle(false)
    setErrorWithTimezone(false)

    const decisionDataForAPI = {
      decision: {
        id: decisionData?.isTemplate ? parseInt(decisionData.id) : parseInt(decisionData["decision-id"]),
        slug: subdomain ? subdomain.toLowerCase() : null,
        country: country.value,
        time_zone: timezone.value,
        open_at: openAt,
        title: title
      }
    };

    if (hasHTML(title)) {
      setErrorWithTitleText("The title can't contain HTML")
      setErrorWithTitle(true)
      errorFound = true
    } else if (title.length < 2) {
      setErrorWithTitle(true)
      setErrorWithTitleText("The title needs at least 2 characters")
      errorFound = true
    } else if (title.length > 255) {
      setErrorWithTitleText("The title can't have more than 255 characters")
      setErrorWithTitle(true)
      errorFound = true
    }

    if (subdomain.length < 2) {
      setErrorWithSubdomain(true)
      setErrorWithSubdomainText("The subdomain needs at least 2 characters")
      errorFound = true
    } else if (title.length > 255) {
      setErrorWithSubdomain(true)
      setErrorWithSubdomainText("The subdomain can't have more than 255 characters")
      errorFound = true
    }

    if (!getCodes().includes(country.value)) {
      setErrorWithCountry(true)
      setErrorWithCountryText("The name of the country is not valid")
      errorFound = true
    }

    const timezones = moment.tz.names();

    if (!timezones.includes(timezone.value)) {
      setErrorWithTimezone(true)
      setErrorWithTimezoneText("The timezone is not valid")
      errorFound = true
    }


    if (errorFound) {
      return
    }

    setCreatingDecision(true)
    setLoading(true)
    decisionApi.createDecisions(decisionDataForAPI).then(e => {
      setEditDecisionLink(e.data.links.edit)
      setCreatingDecision(false)
      setOpenModal(true)
      setLoading(false)
    }).catch(e => {
      setCreatingDecision(false)
      setLoading(false)
      e?.data?.errors?.forEach(currentError => {
        switch (currentError.source.pointer) {
          case "/data/attributes/country":
            setErrorWithCountry(true)
            setErrorWithCountryText(getErrorMessage(currentError.detail))
          case "/data/attributes/time-zone":
            setErrorWithTimezone(true)
            setErrorWithTimezoneText(getErrorMessage(currentError.detail))
          case "/data/attributes/title":
            setErrorWithTitle(true)
            setErrorWithTitleText(getErrorMessage(currentError.detail))
          case "/data/attributes/slug":
            setErrorWithSubdomain(true)
            setErrorWithSubdomainText(getErrorMessage(currentError.detail))
        }

      })
    }
    )
  }

  useEffect(() => {
    if (errorWithTitle) {
      setErrorWithTitle(false)
    }
  }, [title])

  useEffect(() => {
    if (errorWithSubdomain) {
      setErrorWithSubdomain(false)
    }
  }, [subdomain])

  useEffect(() => {
    if (errorWithCountry) {
      setErrorWithCountry(false)
    }
  }, [country])

  useEffect(() => {
    if (errorWithTimezone) {
      setErrorWithTimezone(false)
    }
  }, [timezone])

  useEffect(() => {
    if (isEmptyObject(createDecisionData)) {
      navigate("/create-decisions")
    }
  }, [])

  useEffect(() => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimezone(getTimezoneObject(userTimezone))
  }, []);

  return (
    <>
      <LoadingScreen loading={loading} />
      <Card className="card-create-decision-form" role="form" aria-hidden={openModal}>
        <Link as={RouterLink} to="/create-decisions" className="go-back-link-create-decision-form">
          <Flex className="go-back-flex-create-decision-form">
            <LeftArrow active={true} />
            <Text className="go-back-text-create-decision-form">{t("go-back")}</Text>
          </Flex>
        </Link>
        <Text className="header-text-create-decision-form" role="heading" aria-level="1">
          {decisionData?.isTemplate ? `${t("use")}` + " " : `${t("copy")}` + " "}{decisionData?.title}{decisionData?.isTemplate ? ` ${t("template")}` : ` ${t("decision")}`}
        </Text>
        <Text className="type-of-decision-text-create-decision-form" role="heading" aria-level="2">
          {decisionData?.isTemplate ? t("your-decision-will-be-a-copy") : t("decision-based-on-template")}
        </Text>
        <CardBody className="card-body-create-decision-form">
          <InputGroup className="first-input-group-create-decision-form" >
            <Flex className="flex-column-create-decision-form">
              <Text className="input-text-create-decision-form" role="heading" aria-level="3" id="title">
                {t("title")} *
              </Text>
              <Input type="text" aria-labelledby="title" value={title} onChange={e => setTitle(e.target.value)} className="input-create-decision-form" borderColor={errorWithTitle ? "var(--errorColor)" : "var(--inputBorderColor)"} />
              {errorWithTitle && <Text className="error-text-create-decision-form">{errorWithTitleText}</Text>}
            </Flex>
          </InputGroup>
          <InputGroup className="input-group-create-decision-form">
            <Flex className="flex-column-create-decision-form">
              <Text className="second-input-text-create-decision-form" role="heading" aria-level="3" id="subdomain">
                {t("subdomain-address")} *
              </Text>
              <Text className="subdomain-text-create-decision-form" role="heading" aria-level="4">{t("your-decision-will-be-located-at")} https:://SUBDOMAIN.ethelo.net</Text>
              <Input type="text" aria-labelledby="subdomain" value={subdomain} onChange={e => setSubdomain(e.target.value)} className="input-create-decision-form" borderColor={errorWithSubdomain ? "var(--errorColor)" : "var(--inputBorderColor)"} />
              {errorWithSubdomain && <Text className="error-text-create-decision-form">{errorWithSubdomainText}</Text>}
            </Flex>
          </InputGroup>
          <InputGroup className="input-group-create-decision-form">
            <Flex className="flex-column-create-decision-form">
              <Text className="input-text-create-decision-form" role="heading" aria-level="3" id="country">
                {t("country")} *
              </Text>
              <SelectWithSearch aria-labelledby="country" menuPortalTarget={document.body} {...selectWithSearchStyles} borderColor={errorWithCountry ? "var(--errorColor)" : "var(--inputBorderColor)"} chakraStyles={chakraStyles} options={countries} placeholder={t("select-a-country")} value={country} onChange={setCountry} />
              {errorWithCountry && <Text className="error-text-create-decision-form">{errorWithCountryText}</Text>}
            </Flex>
          </InputGroup>
          <InputGroup className="input-group-create-decision-form">
            <Flex className="flex-column-create-decision-form">
              <Text className="input-text-create-decision-form" role="heading" aria-level="3" id="time-zone">
                {t("time-zone")} *
              </Text>
              <SelectWithSearch aria-labelledby="time-zone" menuPortalTarget={document.body} {...selectWithSearchStyles} borderColor={errorWithTimezone ? "var(--errorColor)" : "var(--inputBorderColor)"} chakraStyles={chakraStyles} options={timezonesToDisplay} placeholder={t("select-a-timezone")} value={timezone} onChange={setTimezone} />
              {errorWithTimezone && <Text className="error-text-create-decision-form">{errorWithTimezoneText}</Text>}
            </Flex>
          </InputGroup>
          <InputGroup>
            <Flex className="flex-column-create-decision-form">
              <Text className="input-text-create-decision-form" role="heading" aria-level="3" id="open-date">
                {t("open-date-and-time")}
              </Text>
              <Input type="datetime-local" aria-labelledby="open-date" value={openAt} onChange={e => setOpenAt(e.target.value)} className="input-create-decision-form" />
            </Flex>
          </InputGroup>
        </CardBody>
        <Button isDisabled={creatingDecision} onClick={() => createDecision()} className="create-decision-button-create-decision-form">{creatingDecision ? t("creating-decision") : t("create-decision")}</Button>
      </Card>
      <CreateDecisionModal editDecisionLink={editDecisionLink} openModal={openModal} />
    </>
  );
};

export default CreateDecisionForm;
