import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from "../Sidebar";
import { Box, Flex } from '@chakra-ui/react';

const SidebarLayout = ({ isOpen, onClose, changeBetweenLightAndDarkMode, lightMode }) => {
    return (
        <Flex height="100%">
            <Sidebar isOpen={isOpen} onClose={onClose} lightMode={lightMode} changeBetweenLightAndDarkMode={changeBetweenLightAndDarkMode} />
            <Box flex="1" >
                <Outlet />
            </Box>
        </Flex>
    );
}

export default SidebarLayout;

