import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Text,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Link,
  Input,
  Checkbox,
  FormControl,
  FormLabel,
  SimpleGrid,
  Tooltip,
  Box,
} from "@chakra-ui/react";
import "./styles.css";

export default function ModalInviteParticipants({
  isOpen,
  onClose,
  currentDecision,
}) {
  const [selectedOption, setSelectedOption] = useState("");

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        borderRadius="16px"
        maxWidth={{ base: "90%", sm: "80%", md: "70%", lg: "60%" }}
      >
        <ModalBody paddingY={4}>
          <Tabs isFitted variant="unstyled">
            <Flex justifyContent="center" mb="1em">
              <TabList maxWidth="500px" width="100%">
                <Tab
                  className={`tab-modal-invite-participants`}
                  _selected={{
                    borderBottom: "3px var(--MAIN-GREY) solid !important",
                    outline: "none !important",
                  }}
                >
                  Invite Participants
                </Tab>
                <Tab
                  className={`tab-modal-invite-participants`}
                  _selected={{
                    borderBottom: "3px var(--MAIN-GREY) solid !important",
                    outline: "none !important",
                  }}
                >
                  Upload Participant
                </Tab>
              </TabList>
            </Flex>
            <TabPanels>
              <TabPanel>
                <Text className="invite-participants-text-modal-invite-participants">
                  Here you can invite participants.
                </Text>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mt={4}>
                  <FormControl gridColumn={{ base: "span 1", md: "span 1" }}>
                    <FormLabel
                      htmlFor="first-name"
                      className="input-label-modal-invite-participants"
                    >
                      Name
                    </FormLabel>
                    <Input
                      id="first-name"
                      className="input-modal-invite-participants"
                    />
                  </FormControl>

                  <FormControl gridColumn={{ base: "span 1", md: "span 1" }}>
                    <FormLabel
                      htmlFor="email"
                      className="input-label-modal-invite-participants"
                    >
                      User E-Mail or Wallet Public Address
                    </FormLabel>
                    <Input
                      id="email"
                      className="input-modal-invite-participants"
                    />
                  </FormControl>

                  <FormControl
                    gridColumn={{ base: "span 1", md: "span 2" }}
                    position="relative"
                  >
                    <Box display="flex" justifyContent="space-between">
                      <FormLabel
                        htmlFor="influence"
                        className="input-label-modal-invite-participants"
                      >
                        Influence
                      </FormLabel>
                      <Tooltip
                        label="User votes will be weighted by this value (ie, 2 = twice as important). Demo participants are never counted, regardless of influence."
                        aria-label="Influence information"
                        placement="top-end"
                        hasArrow
                      >
                        <Box
                          as="span"
                          cursor="pointer"
                          fontWeight="bold"
                          color="white"
                          _hover={{ color: "white" }}
                          background="black"
                          borderRadius="full"
                          height={6}
                          width={6}
                          textAlign="center"
                        >
                          !
                        </Box>
                      </Tooltip>
                    </Box>
                    <Input
                      id="influence"
                      className="input-modal-invite-participants"
                    />
                  </FormControl>
                </SimpleGrid>
                <Checkbox
                  size="lg"
                  iconSize="1.5em"
                  mt={4}
                  sx={{
                    "span.chakra-checkbox__control": {
                      width: "24px",
                      height: "24px",
                      backgroundColor: "var(--INPUT-BACKGROUND)",
                      boxShadow: "none", // Remove the blue shadow
                      _checked: {
                        bg: "var(--INPUT-BACKGROUND)", // Keep the background color when checked
                        color: "var(--MAIN-GREY)", // Set the color of the check mark
                      },
                    },
                    "span.chakra-checkbox__control[data-checked]": {
                      backgroundColor: "var(--INPUT-BACKGROUND)", // Keep the background color when checked
                      color: "var(--MAIN-GREY)", // Red check mark
                    },
                    "span.chakra-checkbox__control:focus": {
                      boxShadow: "none", // Remove focus shadow
                    },
                  }}
                >
                  <span className="checkbox-text-modal-invite-participants">
                    Send Email Immediately
                  </span>
                </Checkbox>

                <Flex align="center" mt={1} mb={2}>
                  <Text
                    mt={1}
                    mb={1}
                    className="roles-modal-invite-participants"
                  >
                    Roles
                  </Text>
                  <Tooltip
                    label={`Admin: Full Access to Configure Decision\n Moderator: Able to log in to the admin panel and moderate comments\n Participant: Access to site, including ability to vote, comment and/or answer questions`}
                    aria-label="Roles information"
                    placement="top-end"
                    hasArrow
                  >
                    <Box
                      as="span"
                      ml={2}
                      cursor="pointer"
                      fontWeight="bold"
                      color="white"
                      _hover={{ color: "white" }}
                      background="black"
                      borderRadius="full"
                      height={6}
                      width={6}
                      textAlign="center"
                      lineHeight="24px"
                    >
                      !
                    </Box>
                  </Tooltip>
                </Flex>
                <Flex direction="row" gap={4}>
                  <Checkbox
                    isChecked={selectedOption === "email"}
                    onChange={() => setSelectedOption("email")}
                    className="checkbox-text-modal-invite-participants"
                    size="lg"
                    iconSize="1.5em"
                    sx={{
                      "span.chakra-checkbox__control": {
                        width: "24px",
                        height: "24px",
                        backgroundColor: "var(--INPUT-BACKGROUND)",
                        boxShadow: "none",
                        _checked: {
                          bg: "var(--INPUT-BACKGROUND)",
                          color: "var(--MAIN-GREY)",
                        },
                      },
                      "span.chakra-checkbox__control[data-checked]": {
                        backgroundColor: "var(--INPUT-BACKGROUND)",
                        color: "var(--MAIN-GREY)",
                      },
                      "span.chakra-checkbox__control:focus": {
                        boxShadow: "none",
                      },
                    }}
                  >
                    <span className="checkbox-text-modal-invite-participants">
                      Admin
                    </span>
                  </Checkbox>

                  <Checkbox
                    isChecked={selectedOption === "link"}
                    onChange={() => setSelectedOption("link")}
                    className="checkbox-text-modal-invite-participants"
                    size="lg"
                    iconSize="1.5em"
                    sx={{
                      "span.chakra-checkbox__control": {
                        width: "24px",
                        height: "24px",
                        backgroundColor: "var(--INPUT-BACKGROUND)",
                        boxShadow: "none",
                        _checked: {
                          bg: "var(--INPUT-BACKGROUND)",
                          color: "var(--MAIN-GREY)",
                        },
                      },
                      "span.chakra-checkbox__control[data-checked]": {
                        backgroundColor: "var(--INPUT-BACKGROUND)",
                        color: "var(--MAIN-GREY)",
                        outline: "none",
                      },
                      "span.chakra-checkbox__control:focus": {
                        boxShadow: "none",
                        outline: "none",
                      },
                    }}
                  >
                    <span className="checkbox-text-modal-invite-participants">
                      Moderator
                    </span>
                  </Checkbox>

                  <Checkbox
                    isChecked={selectedOption === "wallet"}
                    onChange={() => setSelectedOption("wallet")}
                    className="checkbox-text-modal-invite-participants"
                    size="lg"
                    iconSize="1.5em"
                    sx={{
                      "span.chakra-checkbox__control": {
                        width: "24px",
                        height: "24px",
                        backgroundColor: "var(--INPUT-BACKGROUND)",
                        boxShadow: "none",
                        _checked: {
                          bg: "var(--INPUT-BACKGROUND)",
                          color: "var(--MAIN-GREY)",
                        },
                      },
                      "span.chakra-checkbox__control[data-checked]": {
                        backgroundColor: "var(--INPUT-BACKGROUND)",
                        color: "var(--MAIN-GREY)",
                      },
                      "span.chakra-checkbox__control:focus": {
                        boxShadow: "none",
                      },
                    }}
                  >
                    <span className="checkbox-text-modal-invite-participants">
                      Participant
                    </span>
                  </Checkbox>
                </Flex>
              </TabPanel>

              <TabPanel>
                <Text
                  mb={4}
                  textAlign="center"
                  className="text-modal-invite-participants"
                >
                  Upload a file or fill in the text below. The first row of your
                  import must be "name, email, public_address" or
                  "name[TAB]email[TAB]public_address".
                </Text>

                <Text
                  mt={4}
                  textAlign="center"
                  className="text-modal-invite-participants"
                >
                  The invited person can sign up using their wallet at{" "}
                  <Link
                    href="https://token-test.staging.ethelo.net/login/wallet"
                    isExternal
                  >
                    https://token-test.staging.ethelo.net/login/wallet
                  </Link>
                </Text>

                <Text
                  textAlign="center"
                  className="text-modal-invite-participants"
                >
                  Example link:{" "}
                  <Link href="http://www.examplelink.com" isExternal>
                    www.examplelink.com
                  </Link>
                </Text>

                <Flex
                  mb={4}
                  justifyContent="center"
                  alignItems="center"
                  borderTop="2px var(--INPUT-BACKGROUND) solid"
                  pt={8}
                >
                  <Input
                    className="input-modal-invite-participants"
                    type="file"
                    mr={2}
                    variant="outline"
                    sx={{
                      "::-webkit-file-upload-button": {
                        display: "none",
                        marginTop: "-8px !important",
                      },
                      height: "50px",
                      padding: "0.5em",
                    }}
                  />
                  <Button className="submit-button-modal-invite-participants">
                    Upload File
                  </Button>
                </Flex>

                <Checkbox
                  size="lg"
                  iconSize="1.5em"
                  sx={{
                    "span.chakra-checkbox__control": {
                      width: "24px",
                      height: "24px",
                      backgroundColor: "var(--INPUT-BACKGROUND)",
                      boxShadow: "none", // Remove the blue shadow
                      _checked: {
                        bg: "var(--INPUT-BACKGROUND)", // Keep the background color when checked
                        color: "var(--MAIN-GREY)", // Set the color of the check mark
                      },
                    },
                    "span.chakra-checkbox__control[data-checked]": {
                      backgroundColor: "var(--INPUT-BACKGROUND)", // Keep the background color when checked
                      color: "var(--MAIN-GREY)", // Red check mark
                    },
                    "span.chakra-checkbox__control:focus": {
                      boxShadow: "none", // Remove focus shadow
                    },
                  }}
                >
                  <span className="checkbox-text-modal-invite-participants">
                    Send Email Immediately
                  </span>
                </Checkbox>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter margin={0} pt={0} marginBottom={2}>
          <Flex w="100%" justifyContent="space-between" gap={4}>
            <Button
              className="first-button-modal-invite"
              onClick={() => onClose()}
            >
              Go Back
            </Button>
            <Button
              className="second-button-modal-invite"
              onClick={() => onClose()}
            >
              Confirm
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
