import React, { useState, useEffect, useRef } from "react";
import { Link as LinkRouter } from "react-router-dom";
import { useCurrentUserContext } from "features/user/contexts/user-provider";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  Text,
  Box,
  Image,
  Link,
  Avatar,
  IconButton,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

export default function HeaderComponent({
  onOpen,
  onClose,
  isOpen,
  changeBetweenLightAndDarkMode,
  lightMode,
}) {
  const { authorized, logout, user, isLoaded } = useCurrentUserContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [t] = useTranslation("Header");
  const [navbarHeight, setNavbarHeight] = useState(0);
  const navbarRef = useRef(null);

  useEffect(() => {
    if (navbarRef.current) {
      setNavbarHeight(navbarRef.current.offsetHeight);
    }
  }, [navbarRef, isLoaded, authorized]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <Flex
        className={`flex  ${
          authorized ? "flex-authorized" : "flex-unauthorized"
        }`}
        role="navigation"
        aria-label="Header Navigation"
        ref={navbarRef}
      >
        <Flex className="flexWithLogos">
          {isLoaded && authorized && (
            <IconButton
              className="iconButton"
              style={{ backgroundColor: "var(--CLEAR-BACKGROUND)" }}
              onClick={isOpen ? onClose : onOpen}
              size={{ base: "sm", md: "md", lg: "lg" }}
              icon={
                <HamburgerIcon
                  className="hamburguerIcon"
                  color="var(--mainTextColor)"
                />
              }
              alt="Hamburguer menu"
              aria-label="Open menu"
            />
          )}
          <Link
            as={LinkRouter}
            to="/"
            className="linkOfLogo"
            _hover={{ textDecoration: "none" }}
          >
            <Image
              className="logoImage"
              src={lightMode ? "polity.png" : "polity_dark_mode.png"}
              alt="polity-logo"
            />
          </Link>
          <Link href="#main" tabIndex="1" aria-label="Main link">
            <Text></Text>
          </Link>
        </Flex>
        <Flex className="flexWithLinks">
          {isLoaded && !authorized && (
            <Link as={LinkRouter} to="/login" className="link">
              {t("login")}
            </Link>
          )}
          {isLoaded && !authorized && (
            <Link as={LinkRouter} to="/signup" className="link">
              {t("sign-up")}
            </Link>
          )}
          {isLoaded && authorized && (
            <Flex className="flexRow">
              <Flex className="flexWithNameAndAvatar">
                {user && user.avatar && (
                  <Avatar className="avatar" src={user.avatar} alt="user" />
                )}
                {user && user.name && (
                  <Flex className="flexWithUserName" direction="column">
                    <Text margin={0} className="name-user-header">
                      {user.name}
                    </Text>
                    <Flex alignItems="center">
                      <Text
                        margin={0}
                        className="name-text-header"
                        as={LinkRouter}
                        to="/account"
                      >
                        My Account
                      </Text>
                      <FontAwesomeIcon
                        icon={isMenuOpen ? faAngleUp : faAngleDown}
                        style={{ marginLeft: "24px", marginTop: "-16px" }}
                        cursor="pointer"
                        onClick={toggleMenu}
                      />
                    </Flex>
                  </Flex>
                )}
              </Flex>

              {isMenuOpen && (
                <Menu isOpen={isMenuOpen}>
                  <MenuButton as={Box} />
                  <MenuList className="menu-list-header" gap={4}>
                    <MenuItem as={LinkRouter} to="/wallet" className="menu-item-header">
                      <Image
                        src={lightMode ? "money.png" : "money_dark_mode.png"}
                        marginRight={3}
                      />
                      {t("my-wallet")}
                    </MenuItem>
                    <MenuItem onClick={() => changeBetweenLightAndDarkMode()} className="menu-item-header">
                      <Flex alignItems="center" direction="row">
                        <Image
                          src={lightMode ? "darkmode.png" : "darkmode_dark.png"}
                          marginRight={3}
                          height="24px"
                        />
                        <Flex direction="column" justify="center" height="24px" padding={0} gap={0}>
                          <Text m={0}>{t("dark-mode")}</Text>
                          <Text fontSize="xs" color="gray.500" m={0}>
                            {lightMode ? t("turn-off") : t("turn-on")}
                          </Text>
                        </Flex>
                      </Flex>
                    </MenuItem>
                    <MenuItem onClick={() => logout()} className="menu-item-header">
                      <Image
                        src={
                          lightMode ? "logout_icon.png" : "logout_icon_dark.png"
                        }
                        marginRight={3}
                      />
                      {t("logout")}
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
      <div style={{ height: navbarHeight }}></div>
    </>
  );
}
