import {
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  InputGroup,
  Text,
  Flex,
  HStack,
} from "@chakra-ui/react";
import { Select as SelectWithSearch } from "chakra-react-select";
import { useState, useEffect } from "react";
import { getNames, getCode, getCodes, getName } from "country-list";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import decisionApi from "api/decisions";
import LoadingScreen from "components/LoadingScreen";
import { chakraStyles, selectWithSearchStyles } from "./selectStyles";
import { getErrorMessage } from "utils";
import "./styles.css";
import CreateDecisionModal from "components/CreateDecisionModal";


const getCurrentDateInISO8601Format = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

const getCountryInFormat = (country) => {
  return {
    label: country,
    value: getCode(country),
  };
};

const getTimezoneObject = (timezone) => {
  const currentTime = moment.tz(timezone);
  const offsetHours = currentTime.utcOffset() / 60;
  return {
    value: timezone,
    label: `(GMT${
      offsetHours >= 0 ? `+${offsetHours}` : offsetHours
    }) ${timezone}`,
  };
};

const getTimezonesInFormat = () => {
  const timezones = moment.tz.names();
  return timezones
    .map((timezone) => {
      const currentTime = moment.tz(timezone);
      const offsetHours = currentTime.utcOffset() / 60;
      return {
        timezone,
        offsetHours,
      };
    })
    .sort((a, b) => a.offsetHours - b.offsetHours)
    .map(({ timezone, offsetHours }) => ({
      value: timezone,
      label: `(GMT${
        offsetHours >= 0 ? `+${offsetHours}` : offsetHours
      }) ${timezone}`,
    }));
};

const ModalCreateDecision = ({ isOpen, onClose, decisionData }) => {
  const [t] = useTranslation("ModalCreateDecision");
  const [openModal, setOpenModal] = useState(false)
  const [editDecisionLink, setEditDecisionLink] = useState("");
  const [subdomain, setSubdomain] = useState("");
  const [title, setTitle] = useState(
    decisionData?.title ? decisionData.title : ""
  );
  const [country, setCountry] = useState(
    decisionData?.country
      ? getCountryInFormat(getName(decisionData.country))
      : getCountryInFormat(getName("CA"))
  );
  const [timezone, setTimezone] = useState(getTimezoneObject("UTC"));
  const [openAt, setOpenAt] = useState(getCurrentDateInISO8601Format());
  const [creatingDecision, setCreatingDecision] = useState(false);
  const [errorWithTitle, setErrorWithTitle] = useState(false);
  const [errorWithTitleText, setErrorWithTitleText] = useState("");
  const [errorWithSubdomain, setErrorWithSubdomain] = useState(false);
  const [errorWithSubdomainText, setErrorWithSubdomainText] = useState("");
  const [errorWithTimezone, setErrorWithTimezone] = useState(false);
  const [errorWithTimezoneText, setErrorWithTimezoneText] = useState("");
  const [errorWithCountry, setErrorWithCountry] = useState(false);
  const [errorWithCountryText, setErrorWithCountryText] = useState("");
  const [loading, setLoading] = useState(false);

  const countries = getNames().map((name) => getCountryInFormat(name));
  const timezonesToDisplay = getTimezonesInFormat();

  const hasHTML = (str) => {
    const htmlRegex = /<[a-z][\s\S]*>/;

    return htmlRegex.test(str);
  };

  const createDecision = () => {
    let errorFound = false;

    setErrorWithCountry(false);
    setErrorWithSubdomain(false);
    setErrorWithTitle(false);
    setErrorWithTimezone(false);

    const decisionDataForAPI = {
      decision: {
        id: decisionData?.isTemplate
          ? parseInt(decisionData.id)
          : parseInt(decisionData["decision-id"]),
        slug: subdomain ? subdomain.toLowerCase() : null,
        country: country.value,
        time_zone: timezone.value,
        open_at: openAt,
        title: title,
      },
    };

    if (hasHTML(title)) {
      setErrorWithTitleText("The title can't contain HTML");
      setErrorWithTitle(true);
      errorFound = true;
    } else if (title.length < 2) {
      setErrorWithTitle(true);
      setErrorWithTitleText("The title needs at least 2 characters");
      errorFound = true;
    } else if (title.length > 255) {
      setErrorWithTitleText("The title can't have more than 255 characters");
      setErrorWithTitle(true);
      errorFound = true;
    }

    if (subdomain.length < 2) {
      setErrorWithSubdomain(true);
      setErrorWithSubdomainText("The subdomain needs at least 2 characters");
      errorFound = true;
    } else if (title.length > 255) {
      setErrorWithSubdomain(true);
      setErrorWithSubdomainText(
        "The subdomain can't have more than 255 characters"
      );
      errorFound = true;
    }

    if (!getCodes().includes(country.value)) {
      setErrorWithCountry(true);
      setErrorWithCountryText("The name of the country is not valid");
      errorFound = true;
    }

    const timezones = moment.tz.names();

    if (!timezones.includes(timezone.value)) {
      setErrorWithTimezone(true);
      setErrorWithTimezoneText("The timezone is not valid");
      errorFound = true;
    }

    if (errorFound) {
      return;
    }

    setCreatingDecision(true);
    setLoading(true);
    decisionApi
      .createDecisions(decisionDataForAPI)
      .then((e) => {
        setEditDecisionLink(e.data.links.edit);
        setCreatingDecision(false);
        setOpenModal(true);
        setLoading(false);
        onClose()
      })
      .catch((e) => {
        setCreatingDecision(false);
        setLoading(false);
        e?.data?.errors?.forEach((currentError) => {
          switch (currentError.source.pointer) {
            case "/data/attributes/country":
              setErrorWithCountry(true);
              setErrorWithCountryText(getErrorMessage(currentError.detail));
            case "/data/attributes/time-zone":
              setErrorWithTimezone(true);
              setErrorWithTimezoneText(getErrorMessage(currentError.detail));
            case "/data/attributes/title":
              setErrorWithTitle(true);
              setErrorWithTitleText(getErrorMessage(currentError.detail));
            case "/data/attributes/slug":
              setErrorWithSubdomain(true);
              setErrorWithSubdomainText(getErrorMessage(currentError.detail));
          }
        });
      });
  };

  useEffect(() => {
    if (errorWithTitle) {
      setErrorWithTitle(false);
    }
  }, [title]);

  useEffect(() => {
    if (errorWithSubdomain) {
      setErrorWithSubdomain(false);
    }
  }, [subdomain]);

  useEffect(() => {
    if (errorWithCountry) {
      setErrorWithCountry(false);
    }
  }, [country]);

  useEffect(() => {
    if (errorWithTimezone) {
      setErrorWithTimezone(false);
    }
  }, [timezone]);

  useEffect(() => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimezone(getTimezoneObject(userTimezone));
  }, []);

  useEffect(()=> {
    setSubdomain("");
    setTitle(decisionData?.title ? decisionData.title : "");
    setCountry(decisionData?.country ? getCountryInFormat(getName(decisionData.country)) : getCountryInFormat(getName("CA")));
    setTimezone(getTimezoneObject("UTC"));
    setOpenAt(getCurrentDateInISO8601Format());
  },[isOpen])

  return (
    <>
      <CreateDecisionModal editDecisionLink={editDecisionLink} openModal={openModal} setOpenModal={setOpenModal}/>
      <LoadingScreen loading={loading} />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: "md", md: "xl", lg: "2xl" }}
        className="modal-modal-create-decision"
      >
        <ModalOverlay />
        <ModalContent className="modal-content-modal-create-decision">
          <ModalBody className="modal-body-modal-create-decision">
            <Box className="box-text-modal-create-decision">
              <Text
                className="header-text-modal-create-decision"
                role="heading"
                aria-level="1"
              >
                {decisionData?.isTemplate
                  ? `${t("use")}` + " "
                  : `${t("copy")}` + " "}
                {decisionData?.title}
                {decisionData?.isTemplate
                  ? ` ${t("template")}`
                  : ` ${t("decision")}`}
              </Text>
              <Text
                className="type-of-decision-text-modal-create-decision"
                role="heading"
                aria-level="2"
              >
                {decisionData?.isTemplate
                  ? t("your-decision-will-be-a-copy")
                  : t("decision-based-on-template")}
              </Text>
            </Box>
            <InputGroup>
              <Flex className="flex-input-modal-create-decision">
                <Text className="text-input-modal-create-decision">
                  {t("Title")}
                </Text>
                <Input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  borderColor={errorWithTitle ? "red.500" : "gray.200"}
                  className="input-modal-create-decision"
                />
                {errorWithTitle && (
                  <Text color="red.500">{errorWithTitleText}</Text>
                )}
              </Flex>
            </InputGroup>

            <InputGroup mt={4}>
              <Flex className="flex-input-modal-create-decision">
                <Text className="text-input-modal-create-decision">
                  {t("Subdomain Address")}
                </Text>
                <Input
                  type="text"
                  value={subdomain}
                  onChange={(e) => setSubdomain(e.target.value)}
                  borderColor={errorWithSubdomain ? "red.500" : "gray.200"}
                  className="input-modal-create-decision"
                />
                {errorWithSubdomain && (
                  <Text color="red.500">{errorWithSubdomainText}</Text>
                )}
              </Flex>
            </InputGroup>

            <InputGroup mt={4}>
              <Flex className="flex-input-modal-create-decision">
                <Text className="text-input-modal-create-decision">
                  {t("Country")}
                </Text>
                <SelectWithSearch
                  options={countries}
                  value={country}
                  onChange={setCountry}
                  chakraStyles={chakraStyles}
                  {...selectWithSearchStyles}
                  placeholder={t("Select a country")}
                />
                {errorWithCountry && (
                  <Text color="red.500">{errorWithCountryText}</Text>
                )}
              </Flex>
            </InputGroup>

            <InputGroup mt={4}>
              <Flex className="flex-input-modal-create-decision">
                <Text className="text-input-modal-create-decision">
                  {t("Timezone")}
                </Text>
                <SelectWithSearch
                  options={timezonesToDisplay}
                  value={timezone}
                  onChange={setTimezone}
                  chakraStyles={chakraStyles}
                  {...selectWithSearchStyles}
                  placeholder={t("Select a timezone")}
                />
                {errorWithTimezone && (
                  <Text color="red.500">{errorWithTimezoneText}</Text>
                )}
              </Flex>
            </InputGroup>
            <InputGroup mt={4}>
              <Flex className="flex-input-modal-create-decision">
                <Text className="text-input-modal-create-decision">
                {t("open-date-and-time")}
                </Text>
                <Input type="datetime-local" aria-labelledby="open-date" value={openAt} onChange={e => setOpenAt(e.target.value)} className="input-modal-create-decision" />
                {errorWithTimezone && (
                  <Text color="red.500">{errorWithTimezoneText}</Text>
                )}
              </Flex>
            </InputGroup>            
          </ModalBody>

          <ModalFooter>
            <HStack spacing={4} w="100%">
              <Button
                onClick={onClose}
                className="button-cancel-modal-create-decision"
                isDisabled={creatingDecision}
                flex="1"
              >
                {t("go-back")}
              </Button>
              <Button
                isDisabled={creatingDecision}
                onClick={() => createDecision()}
                className="button-create-modal-create-decision"
                flex="1"
              >
                {t("create-decision")}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalCreateDecision;
