import React, {useState} from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Image,
  Text,
  Link,
  Flex,
  Box,
  Tooltip,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HTMLReactParser from "html-react-parser";
import { timeDifference } from "utils"
import "./styles.css";
import { formatDate } from "utils"


export default function ModalDecisionCardHost({
  isOpen,
  onClose,
  currentDecision,
}) {
  const [isError, setIsError] = useState(false);

  const [t] = useTranslation("ModalDecisionCardHost");

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="16px">
        <ModalBody>
          <Box className="main-box-modal-decision-card-host">
            <Image
              className="image-modal-decision-card-host"
              src={
                isError
                  ? "standar_image.png"
                  : currentDecision["image-url"] || "standar_image.png"
              }
              alt={"Decision"}
              onError={() => setIsError(true)}
            />
            <Text className="title-modal-decision-card-host">
              {currentDecision["title"]}
            </Text>
            <Link
              className="main-link-modal-decision-card-host"
              href={
                currentDecision["current-mode"] === "Unpublished"
                  ? currentDecision?.links?.preview
                  : currentDecision?.links?.visit
              }
              target="_blank"
            >
              {currentDecision["current-mode"] === "Unpublished"
                ? currentDecision?.links?.preview
                : currentDecision?.links?.visit}
            </Link>
            {currentDecision.invited && (
              <Link href={currentDecision?.links?.visit}>
                <Button className="special-link-button-modal-decision-card-host">
                  <FontAwesomeIcon
                    icon="fa-regular fa-envelope"
                    className="icon-button-modal-decision-card-host"
                  />
                  {t("accept-invitation")}
                </Button>
              </Link>
            )}
            {!currentDecision.invited &&
              currentDecision["current-mode"] === "Open" && (
                <Link href={currentDecision?.links?.visit}>
                  <Button className="special-link-button-modal-decision-card-host">
                    <FontAwesomeIcon
                      icon="fa-regular fa-moon"
                      className="icon-button-modal-decision-card-host"
                    />
                    {currentDecision.completed ? currentDecision.completed : 0}%{" "}
                    {t("completed")}
                  </Button>
                </Link>
              )}

            {!currentDecision.invited &&
              currentDecision["current-mode"]?.includes("Finished") && (
                <Link href={currentDecision?.links?.results}>
                  <Button className="special-link-button-modal-decision-card-host">
                    <FontAwesomeIcon
                      icon="fa-regular fa-check"
                      className="icon-button-modal-decision-card-host"
                    />
                    {t("view-results")}
                  </Button>
                </Link>
              )}
          </Box>
          <Flex direction="column">
            <Flex className="date-flex-modal-decision-card-host">
              <Text className="date-tag-modal-decision-card-host">
                {t("opened")}
              </Text>
              <Text ml={0} className="date-text-modal-decision-card-host">
                {currentDecision["open-at"] ? (
                  <>
                    {formatDate(currentDecision["open-at"])} (
                    {timeDifference(currentDecision["open-at"])})
                  </>
                ) : (
                  <>{t("tbd")}</>
                )}
              </Text>
            </Flex>
            <Flex className="date-flex-modal-decision-card-host">
              <Text className="date-tag-modal-decision-card-host">
                {t("closes")}
              </Text>
              <Text ml={0} className="date-text-modal-decision-card-host">
                {currentDecision["close-at"] ? (
                  <>
                    {formatDate(currentDecision["close-at"])} (
                    {timeDifference(currentDecision["close-at"])})
                  </>
                ) : (
                  <>{t("tbd")}</>
                )}
              </Text>
            </Flex>

            {currentDecision.info && (
              <Flex className="about-flex-modal-decision-card-host">
                <Text className="about-text-modal-decision-card-host">
                  {t("about")}
                </Text>
                {HTMLReactParser(currentDecision.info)}
              </Flex>
            )}
          </Flex>
          <Flex
            className="flex-buttons-modal-modal-decision-card-host"
            gap={2}
            direction="row"
          >
            {currentDecision?.links?.visit && (
              <Tooltip label={t("visit")}>
                <Link
                  href={currentDecision?.links?.visit}
                  target="_blank"
                  width="100%"
                >
                  <Button className="icon-bottom-button-modal-decision-card-host">
                    <FontAwesomeIcon
                      icon="fa-solid fa-eye"
                      className="icon-decision-card-host"
                    />
                  </Button>
                </Link>
              </Tooltip>
            )}
            {currentDecision?.links?.edit && (
              <Tooltip label={t("edit")}>
                <Link
                  href={currentDecision?.links?.edit}
                  target="_blank"
                  width="100%"
                >
                  <Button className="icon-bottom-button-decision-card-host">
                    <FontAwesomeIcon
                      icon="fa-solid fa-pen"
                      className="icon-decision-card-host"
                    />
                  </Button>
                </Link>
              </Tooltip>
            )}
            {currentDecision?.links?.analysis && (
              <Tooltip label={t("analysis")}>
                <Link
                  href={currentDecision?.links?.analysis}
                  target="_blank"
                  width="100%"
                >
                  <Button className="icon-bottom-button-decision-card-host">
                    <FontAwesomeIcon
                      icon="fa-solid fa-chart-simple"
                      className="icon-decision-card-host"
                    />
                  </Button>
                </Link>
              </Tooltip>
            )}
            {currentDecision?.links?.comments && (
              <Tooltip label={t("comments")}>
                <Link
                  href={currentDecision?.links?.comments}
                  target="_blank"
                  width="100%"
                >
                  <Button className="icon-bottom-button-decision-card-host">
                    <FontAwesomeIcon
                      icon="fa-solid fa-comment"
                      className="icon-decision-card-host"
                    />
                  </Button>
                </Link>
              </Tooltip>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => onClose()}
            className="button-details-modal-decision-card-host"
            rightIcon={<FontAwesomeIcon icon="fa-solid fa-chevron-up" />}
            justifyContent="space-between"
          >
            {t("hide-details")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
