import { extendTheme } from "@chakra-ui/react";

const fonts = {
    ubuntuFont: "Ubuntu",
    interFont: "Inter"
};

const theme = extendTheme({
    config: {
        initialColorMode: "light",
    },
    /*colors: {
        selectBackgroundMenuColor: "white",
        selectedOptionBackgroundColor: "#55A558",
        tableLinkColor: "#0047AB",
        mainColor: "#f7f7f7",
        formBackground: "#fcfcfc",
        createDecisionCardBackground: "white",
        tableMainBackgroundColor: "white",
        tableSecondaryBackgroundColor: "#EDEDED",
        headerColor: "white",
        secondaryColor: "#083556",
        sidebarColor: "#083556",
        baseLinkColor: "#0047AB",
        hoverLinkColor: "#083556",
        buttonColor: "#1E600F",
        secondaryButtonColor: "black",
        secondaryInputTextColor: "#484848",
        headerBorder: "#323232",
        errorColor: "#f75858",
        cancelColor: "red",
        inputBorderColor: "#D4D4D4",
        mainTextColor: "black",
        secondaryTextColor: "white",
        thirdButtonColor: "blue",
        fourthButtonColor: "white",
        borderColor: "gray",
        secondaryBorderColor: "black",
        mainBackgroundColor: "#F9F9F9",
        secondaryBackgroundColor: "white",
        inputDisabledBorderColor: "#D9D9D9",
        backgroundLoading: "#ffffff80",
        colorLoading: "#3498db",
        cancelButtonStyles: "#B6BBC4",
        cardBackgroundColor: "white",
        clearButtonBackground: "#F7F7F7",
        disabledButtonText: "#D9D9D9",
        inputColor: "#f7f7f7",
        placeholderColor: "#484848",
        menuListColor: "#f7f7f7",
        secondaryInputColor: "white",
        tabPanelsColor: "white",
        tabSelectedTextColor: "white",
        tabSelectedBackgroundColor: "#083556",
        tabSelectedBorderColor: "#083556",
        tabTextColor: "#083556",
        tabBackgroundColor: "transparent",
        tabBorderColor: "#083556",
        templateCardColor: "#f7f7f7",
        templateCardBorderColor: "#f7f7f7",
        paginationButtonTextColor: "#083556",
        paginationButtonTextSelectedColor: "#083556",
        paginationButtonBackgroundColor: "white",
        paginationButtonBackgroundSelectedColor: "rgba(8, 53, 86, 0.25)",
        paginationButtonBorderColor: "#083556",
        tableBorderColor: "#EDEDED",
        progressBarBackgroundColor: "#083556"
    },*/
    fonts,
    components: {
        Button: {
            baseStyle: {
                _active: {
                    boxShadow: "none",
                    border: "none",
                    outline: "none",
                },
                _focus: {
                    boxShadow: "none",
                    border: "none",
                    outline: "none",
                },
            },
        },
        Td: {
            baseStyle: {
                wordWrap: "break-all"
            }
        }
    },
    styles: {
        global: (props) => ({
            body: {
                bg: "mainBackgroundColor",
                color: "black",
            }
        }),
    },
});

const darkTheme = extendTheme({
    config: {
        initialColorMode: "dark",
    },
    /*colors: {
        selectBackgroundMenuColor: "#182631",
        selectedOptionBackgroundColor: "#55A558",
        tableLinkColor: "white",
        headerColor: "#1E2D39",
        formBackground: "#182631",
        secondaryColor: "white",
        tableMainBackgroundColor: "#182631",
        tableSecondaryBackgroundColor: "#050C12",
        createDecisionCardBackground: "#182631",
        sidebarColor: "#083556",
        baseLinkColor: "#0071c5",
        hoverLinkColor: "#00BFFF",
        buttonColor: "#55A558",
        secondaryInputTextColor: "white",
        secondaryButtonColor: "#55A558",
        headerBorder: "brown",
        errorColor: "#f75858",
        cancelColor: "orange",
        inputBorderColor: "#D4D4D4",
        mainTextColor: "white",
        secondaryTextColor: "white",
        thirdButtonColor: "#f8b500",
        fourthButtonColor: "black",
        borderColor: "#c4c1e0",
        secondaryBorderColor: "gray",
        backgroundLoading: "#ffffff80",
        colorLoading: "#3498db",
        mainBackgroundColor: "#08141D",
        cardBackgroundColor: "#1E2D39",
        clearButtonBackground: "#2E4353",
        disabledButtonText: "white",
        inputColor: "#2E4353",
        placeholderColor: "white",
        menuListColor: "#083556",
        secondaryInputColor: "#1E2D39",
        tabPanelsColor: "#0f1b24",
        tabSelectedTextColor: "white",
        tabSelectedBackgroundColor: "#083556",
        tabSelectedBorderColor: "#2b516e",
        tabTextColor: "white",
        tabBackgroundColor: "#1E2D39",
        tabBorderColor: "#083556",
        templateCardColor: "#1E2D39",
        templateCardBorderColor: "#5e5f60",
        paginationButtonTextColor: "white",
        paginationButtonTextSelectedColor: "#083556",
        paginationButtonBackgroundColor: "transparent",
        paginationButtonBackgroundSelectedColor: "white",
        paginationButtonBorderColor: "white",
        tableBorderColor: "#2C3740",
        progressBarBackgroundColor: "#55A558"
    },*/
    fonts,
    components: {
        Button: {
            baseStyle: {
                _active: {
                    boxShadow: "none",
                    border: "none",
                    outline: "none",
                },
                _focus: {
                    boxShadow: "none",
                    border: "none",
                    outline: "none",
                },
            },
        },
    },
    styles: {
        global: (props) => ({
            body: {
                bg: "mainBackgroundColor",
                color: "white"
            },
        }),
    },
});

export { theme, darkTheme };
