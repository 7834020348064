import React from 'react'
import { Box } from '@chakra-ui/react'
import CreateDecisionForm from "components/CreateDecisionForm"
import "./styles.css"

export default function CreateDecisionFormPage() {
  return (
    <Box className='box-create-decision-form-page' id="main">
      <CreateDecisionForm />
    </Box>
  )
}
