import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";
import {
  Spinner,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import PaymentForm from "../PaymentForm";
import { useCurrentUserContext } from "features/user/contexts/user-provider";

const publicKeyStripe = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY

const stripe = loadStripe(publicKeyStripe);

const StripePayment = ({
  isOpen,
  onClose,
  paymentInformation,
  paymentDescription,
  paymentAmount,
  clientSecret
}) => {
  /*const [clientSecret, setClientSecret] = useState(null);
  const paymentEndpoint = paymentDescription === "tokens" ? "buy-tokens" : "buy-subscription";
  const { user } = useCurrentUserContext();
  const { email, id } = user;

  const handlePaymentIntent = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_STRIPE_BACKEND_URL}/${paymentEndpoint}`,
        {
          payment: [{ description: paymentDescription, amount: paymentAmount }],
          userId: id,
          email,
        }
      );

      setClientSecret(response.data.clientSecret);
    } catch (error) {
      console.error("Error creating payment intent:", error);
    } finally {
    }
  };

  const options = {
    clientSecret,
    theme: "stripe",
  };*/

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{paymentInformation}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box display="flex" justifyContent="center" alignItems="center">
              {clientSecret ? (
                <Elements stripe={stripe} options={{clientSecret, theme: "stripe"}}>
                  <PaymentForm />
                </Elements>
              ) : (
                <Spinner size="xl" />
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default StripePayment;
