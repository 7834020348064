import React, { useState } from "react";
import { Card, Flex, Box, Text, Button, useDisclosure } from "@chakra-ui/react";
import StripePayment from "components/StripePayment";
import { useCurrentUserContext } from "features/user/contexts/user-provider";
import { useCurrentPaymentsContext } from "features/payments/contexts/payments-provider";
import axios from "axios";
import "./styles.css";

export default function PaymentItems() {
  const [clientSecret, setClientSecret] = useState(null);
  const { user } = useCurrentUserContext();
  const { email, id } = user;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [paymentInformation, setPaymentInformation] = useState("");
  const [paymentDescription, setPaymentDescription] = useState("");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const { subscription } = useCurrentPaymentsContext();
  const subscriptionPrice = process.env.REACT_APP_SUBSCRIPTION_PRICE
  const sharePrice = process.env.REACT_APP_INVESTMENT_SHARE_PRICE

  const handlePaymentIntent = async (
    currentPaymentInformation,
    currentPaymentDescription,
    currentPaymentAmount
  ) => {
    try {
      const paymentEndpoint =
        currentPaymentDescription === "tokens"
          ? "buy-tokens"
          : "buy-subscription";
      const response = await axios.post(
        `${process.env.REACT_APP_STRIPE_BACKEND_URL}/${paymentEndpoint}`,
        {
          paymentAmount: currentPaymentAmount,
          description: currentPaymentDescription,
          userId: id,
          email,
        }
      );

      setClientSecret(response.data.clientSecret);
      setPaymentInformation(currentPaymentInformation);
      setPaymentDescription(currentPaymentDescription);
      setPaymentAmount(currentPaymentAmount);
      onOpen();
    } catch (error) {
      console.error("Error creating payment intent:", error);
    } finally {
    }
  };

  return (
    <>
      <StripePayment
        isOpen={isOpen}
        onClose={onClose}
        paymentInformation={paymentInformation}
        paymentDescription={paymentDescription}
        paymentAmount={paymentAmount}
        clientSecret={clientSecret}
      />
      <Flex
        direction={{ base: "column", md: "row" }}
        gap={{ base: 4, md: 8 }}
        py={{ base: 2, md: 4 }}
      >
        {!subscription && <Card flex="1" className="card-form-payment-items">
          <Text className="payment-text-payment-items">Membership token</Text>
          <Flex>
            <Text className="payment-amount-payment-items">$100</Text>
            <Text className="single-payment-payment-items">single payment</Text>
          </Flex>
          <Box p={4}>
            <Flex alignItems="flex-start" mb={2}>
              <Box
                w="10px"
                h="10px"
                borderRadius="50%"
                bg="blue.500"
                mr={2}
                position="relative"
                top="6.5px"
                className="circle-payment-items"
              />
              <Text className="text-circle-payment-items">
                Lorem Ipsum is simply dummy text of the <br />
                printing and typesetting
                industry.
              </Text>
            </Flex>
            <Flex alignItems="center" mb={2}>
              <Box
                w="10px"
                h="10px"
                borderRadius="50%"
                bg="blue.500"
                mr={2}
                position="relative"
                top="-8px"
                className="circle-payment-items"
              />
              <Text className="text-circle-payment-items">
                When an unknown printer took.
              </Text>
            </Flex>
            <Flex alignItems="center">
              <Box
                w="10px"
                h="10px"
                borderRadius="50%"
                bg="blue.500"
                mr={2}
                position="relative"
                top="-8px"
                className="circle-payment-items"
              />
              <Text className="text-circle-payment-items" >1500s scrambled</Text>
            </Flex>
            <Button
              className="payment-button-payment-items"
              onClick={() =>
                handlePaymentIntent("Membership token", "subscription", subscriptionPrice)
              }
            >
              Checkout with stripe
            </Button>
            <Button
              className="payment-button-payment-items"
              onClick={() => handlePaymentIntent("Membership token", "subscription", subscriptionPrice)}
            >
              Checkout with crypto
            </Button>            
          </Box>
        </Card>}
        <Card flex="1" className="card-form-payment-items">
          <Text className="payment-text-payment-items">Investment Share</Text>
          <Flex>
            <Text className="payment-amount-payment-items">$10</Text>
            <Text className="single-payment-payment-items">single payment</Text>
          </Flex>
          <Box p={4}>
            <Flex alignItems="flex-start" mb={2}>
              <Box
                w="10px"
                h="10px"
                borderRadius="50%"
                bg="blue.500"
                mr={2}
                position="relative"
                top="6.5px"
                className="circle-payment-items"
              />
              <Text className="text-circle-payment-items">
                Lorem Ipsum is simply dummy text of the <br />
                printing and typesetting
                industry.
              </Text>
            </Flex>
            <Flex alignItems="center" mb={2}>
              <Box
                w="10px"
                h="10px"
                borderRadius="50%"
                bg="blue.500"
                mr={2}
                position="relative"
                top="-8px"
                className="circle-payment-items"
              />
              <Text className="text-circle-payment-items">
                When an unknown printer took.
              </Text>
            </Flex>
            <Flex alignItems="center">
              <Box
                w="10px"
                h="10px"
                borderRadius="50%"
                bg="blue.500"
                mr={2}
                position="relative"
                top="-8px"
                className="circle-payment-items"
              />
              <Text className="text-circle-payment-items">1500s scrambled</Text>
            </Flex>
            <Button
              className="payment-button-payment-items"
              onClick={() => handlePaymentIntent("Investment Share", "tokens", sharePrice)}
            >
              Checkout with stripe
            </Button>
            <Button
              className="payment-button-payment-items"
              onClick={() => handlePaymentIntent("Investment Share", "tokens", sharePrice)}
            >
              Checkout with crypto
            </Button>            
          </Box>
        </Card>
      </Flex>  
    </>
  );
}
