import React from "react";
import { Card, Flex, Stack, CardBody, Text, Avatar } from "@chakra-ui/react";
import "./styles.css";
import { useCurrentUserContext } from "features/user/contexts/user-provider";
import { useTranslation } from "react-i18next";
import "./styles.css";

export default function AccountHeader() {
  const { user } = useCurrentUserContext();
  const [t] = useTranslation("AccountHeader");

  return (
    <Card variant="outline" className="card-account-header">
      <Flex className="flex-account-header">
        <Avatar src={user.avatar} className="avatar-account-header" />
        <Stack role="main" aria-label="Create decision card">
          <CardBody>
            <Text className="username-acount-header">
              {user.name}
            </Text>
            <Text className="user-title-account-header">
              {t("admin")}
            </Text>
          </CardBody>
        </Stack>
      </Flex>
    </Card>
  );
}
