import {useState} from "react"
import {
    Flex,
    Center,
    Button,
    Stack,
    Box,
    Link,
    Text
} from "@chakra-ui/react";
import userApi from 'api/auth';
import connectWallet from 'features/web3/utils/connectMetamask';
import personalSign from 'features/web3/utils/personalSign';
import { useCurrentUserContext } from 'features/user/contexts/user-provider';
import { Link as RouterLink, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import "./styles.css"
import { getErrorMessage } from "utils"


const LoginWithMetamaskInstalled = ({ setLoginWithMetamask }) => {
    const [t] = useTranslation('LoginWithMetamaskInstalled');
    const { login } = useCurrentUserContext();
    const navigate = useNavigate()
    const [web3Error, setWeb3Error] = useState(false)
    const [web3TextError, setWeb3TextError] = useState("")

    const connectWithMetamask = async (nonce, publicAddress) => {
        const message = `Ethelo Login,${Date.now()},${nonce}`;
        const { signature } = await personalSign({ message, publicAddress });
        if (signature) {
            const user = {
                public_address: publicAddress,
                message,
                signature,
            };
            setWeb3TextError(setWeb3Error)
            userApi['loginWithMetamask']({ user })
                .then(() => {
                    login();
                    navigate('/', { replace: true });
                })
                .catch((e) => {
                    e?.data?.errors?.forEach(currentError => {
                         if(currentError.source.pointer === "/data/attributes/auth"){
                            setWeb3Error(true)
                            setWeb3TextError(getErrorMessage(currentError.detail))
                        }
                })
                });
        } else {
        }
    };

    const loginInAppWithWeb3 = async () => {
        const { publicAddress } = await connectWallet();
        if (publicAddress) {
            userApi.nonce({ user: { public_address: publicAddress } })
                .then(response => {
                    connectWithMetamask(response.nonce, publicAddress);
                })
                .catch(() => {
                });
        } else {
        }
    };

    return (
        <Flex className="main-flex-login-with-metamask">
            <Stack className="main-stack-login-with-metamask">
                <Box className="main-box-login-with-metamask">
                    <Stack className="secondary-stack-login-with-metamask" role="main" aria-label="Login buttons"
                    >
                        <Center className="signin-center-1-login-with-metamask">
                            <Box className="signin-box-with-text-login-with-metamask" role="heading" aria-level="1">{t("sign-in")}</Box>
                        </Center>
                        <Center>
                            <Box className="box-with-text-login-with-metamask" role="heading" aria-level="2">{t("not-registered-yet")} <Link as={RouterLink} className="link-login-with-metamask" to="/signup" color="var(--baseLinkColor)" _hover={{ color: "var(--hoverLinkColor)", textDecoration: "none" }}>{" "}{t("sign-up")}</Link></Box>
                        </Center>
                        <Box className="signin-box-login-with-metamask">
                            <Center className="signin-center-2-login-with-metamask">
                                <Button
                                    className="signin-button-login-with-metamask"
                                    onClick={() => setLoginWithMetamask(false)}
                                    role="button"
                                >
                                    {t("sign-in-with-password")}
                                </Button>
                            </Center>
                            <Center>
                                <Box className="or-text-login-with-metamask" role="heading" aria-level="3">{t("or")}</Box>
                            </Center>
                            <Flex className="signin-center-2-login-with-metamask" direction="column">
                                <Button
                                    className="signin-with-metamask-button-login-with-metamask"
                                    onClick={() => loginInAppWithWeb3()}
                                    role="button"
                                >
                                    {t("sign-in-with-metamask")}
                                </Button>
                                {web3Error && <Text className="error-text-login-with-metamask" aria-label={web3TextError}>{web3TextError}</Text>}
                            </Flex>
                        </Box>
                    </Stack>
                </Box>
            </Stack >
        </Flex >
    );
};

export default LoginWithMetamaskInstalled;
