import React, { useState } from "react";
import {
  Button,
  Text,
  Image,
  Box,
  FormControl,
  Input,
  InputGroup,
  Card,
  CardBody,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useCurrentUserContext } from "features/user/contexts/user-provider";
import userApi from "api/user";
import { getErrorMessage } from "utils";
import SuccessModal from "components/SuccessModal";
import "./styles.css";

export default function ChangePassword() {
  const { user: userData } = useCurrentUserContext();
  const [currentPassord, setCurrentPassword] = useState("");
  const [newPassword, setNewPassord] = useState("");
  const [newPasswordVerification, setNewPasswordVerification] = useState("");
  const [t] = useTranslation("ChangePassword");
  const [errorWithCurrentPassword, setErrorWithCurrentPassword] =
    useState(false);
  const [errorWithNewPassword, setErrorWithNewPassword] = useState(false);
  const [
    errorWithNewPasswordVerification,
    setErrorWithNewPasswordVerification,
  ] = useState(false);
  const [errorWithCurrentPasswordText, setErrorWithCurrentPasswordText] =
    useState("");
  const [errorWithNewPasswordText, setErrorWithNewPasswordText] = useState("");
  const [
    errorWithNewPasswordVerificationText,
    setErrorWithNewPasswordVerificationText,
  ] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const editPassword = () => {
    let errorFound = false;

    setErrorWithCurrentPassword(false);
    setErrorWithNewPassword(false);
    setErrorWithNewPasswordVerification(false);

    const newPasswordWithoutTrim = newPassword.trim();

    const userDataForAPI = {
      user: {
        email: userData.email,
        name: userData.name,
        current_password: currentPassord,
        password: newPassword,
        password_confirmation: newPasswordVerification,
      },
    };

    if (!currentPassord) {
      setErrorWithCurrentPassword(true);
      setErrorWithCurrentPasswordText("The password doesn't have a value");
      errorFound = true;
    }

    if (!newPassword) {
      setErrorWithNewPassword(true);
      setErrorWithNewPasswordText("The new password doesn't have a value");
      errorFound = true;
    }

    if (newPassword.length !== newPasswordWithoutTrim.length) {
      setErrorWithNewPassword(true);
      setErrorWithNewPasswordText(
        "The first and last characters can't be spaces"
      );
      errorFound = true;
    }

    if (newPassword !== newPasswordVerification) {
      setErrorWithNewPasswordVerification(true);
      setErrorWithNewPasswordVerificationText("The passwords are not the same");
      errorFound = true;
    }

    if (errorFound) {
      return;
    }

    userApi
      .editProfile(userDataForAPI)
      .then(() => {
        setCurrentPassword("");
        setNewPassord("");
        setNewPasswordVerification("");
        onOpen();
      })
      .catch((e) => {
        e?.data?.errors?.forEach((currentError) => {
          switch (currentError.source.pointer) {
            case "/data/attributes/current-password":
              setErrorWithCurrentPassword(true);
              setErrorWithCurrentPasswordText("Wrong password");
            case "/data/attributes/password":
              setErrorWithNewPassword(true);
              setErrorWithNewPasswordText(getErrorMessage(currentError.detail));
            case "/data/attributes/password-confirmation":
              setErrorWithNewPasswordVerification(true);
              if (currentError.detail === "errors.messages.confirmation") {
                setErrorWithNewPasswordVerificationText(
                  "The passwords are not the same"
                );
              } else {
                setErrorWithNewPasswordVerificationText(
                  getErrorMessage(currentError.detail)
                );
              }
          }
        });
      });
  };

  return (
    <>
      <SuccessModal
        isOpen={isOpen}
        onClose={onClose}
        text={t("password-changed-successfully")}
      />
      <Card
        className="card-change-password"
        role="form"
        aria-label="Change password form"
      >
        <Text
          className="header-text-change-password"
          role="heading"
          aria-level="1"
        >
          {t("change-my-password")}
        </Text>
        <Text className="description-change-password">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s.
        </Text>
        <CardBody className="card-body-change-password">
          <Box className="box-change-password ">
            <Text
              className="input-text-change-password"
              role="heading"
              aria-level="2"
            >
              {t("current-password")}
            </Text>
            <InputGroup>
              <Flex className="flex-column-change-password ">
                <Input
                  className={
                    errorWithCurrentPassword
                      ? "input-change-password-with-error"
                      : "input-change-password"
                  }
                  type="password"
                  value={currentPassord}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
                {errorWithCurrentPassword && (
                  <Text className="error-text-change-password">
                    {errorWithCurrentPasswordText}
                  </Text>
                )}
              </Flex>
            </InputGroup>
          </Box>
          <FormControl className="first-form-control-change-password">
            <Text
              className="input-text-change-password"
              role="heading"
              aria-level="2"
            >
              {t("new-password")}
            </Text>
            <InputGroup>
              <Flex className="flex-column-change-password">
                <Input
                  className={
                    errorWithNewPassword
                      ? "input-change-password-with-error"
                      : "input-change-password"
                  }
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassord(e.target.value)}
                />
                {errorWithNewPassword && (
                  <Text className="error-text-change-password">
                    {errorWithNewPasswordText}
                  </Text>
                )}
              </Flex>
            </InputGroup>
          </FormControl>
          <FormControl>
            <Text
              className="input-text-change-password"
              role="heading"
              aria-level="2"
            >
              {t("repeat-password")}
            </Text>
            <InputGroup>
              <Flex className="flex-column-change-password">
                <Input
                  className={
                    errorWithNewPasswordVerification
                      ? "input-change-password-with-error"
                      : "input-change-password"
                  }
                  type="password"
                  value={newPasswordVerification}
                  onChange={(e) => setNewPasswordVerification(e.target.value)}
                />
                {errorWithNewPasswordVerification && (
                  <Text className="error-text-change-password">
                    {errorWithNewPasswordVerificationText}
                  </Text>
                )}
              </Flex>
            </InputGroup>
          </FormControl>
        </CardBody>
        <Button
          className="button-change-password"
          onClick={() => editPassword()}
        >
          {t("save")}
        </Button>
      </Card>
    </>
  );
}
