import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "features/user/contexts/user-provider";
import { DecisionsProvider } from "features/decisions/contexts/decisions-provider";
import { PaymentsProvider } from "features/payments/contexts/payments-provider";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import i18nextConfig from "./translations/i18nextConfig/i18nextConfig";
import { config } from "./wagmi";
import { WagmiConfig } from "wagmi";
import "./index.css";

i18next.init(i18nextConfig);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <UserProvider>
        <DecisionsProvider>
          <PaymentsProvider>
            <BrowserRouter>
              <WagmiConfig config={config}>
                <App />
              </WagmiConfig>
            </BrowserRouter>
          </PaymentsProvider>
        </DecisionsProvider>
      </UserProvider>
    </I18nextProvider>
  </React.StrictMode>
);
