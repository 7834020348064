import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Link,
  Flex,
  Image,
  VStack,
  Tooltip,
  Collapse,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCurrentUserContext } from "features/user/contexts/user-provider";
import { display } from "@mui/system";

const groupsDataBase = [
  { name: "AGroup", id: 1 },
  { name: "Group2", id: 2 },
  { name: "Group3", id: 3 },
  { name: "Group4", id: 4 },
];

export default function Sidebar({ isOpen, lightMode }) {
  const [t] = useTranslation("Sidebar");
  const [groupsData, setGroupsData] = useState(groupsDataBase);
  const [sidebarWidth, setSidebarWidth] = useState(0);
  const sidebarRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(null);
  const { user } = useCurrentUserContext();
  let isHost = true;

  useEffect(() => {
    if (sidebarRef.current) {
      setSidebarWidth(sidebarRef.current.offsetWidth);
    }
  }, [sidebarRef, isOpen]);

  const linkItems = [
    {
      name: t("decisions"),
      icon: "decisions.png",
      iconDarkMode: "decisions_dark.png",
      link: "/participate",
      alt: `${t("decisions")}`,
      display: !isHost
    },
    {
      name: t("decisions"),
      icon: "decisions.png",
      iconDarkMode: "decisions_dark.png",
      link: "/hosted-decisions",
      alt: `${t("decisions")}`,
      display: isHost
    },
    {
      name: t("edit-group"),
      icon: "document.png",
      iconDarkMode: "document_dark.png",
      link: "/edit-group",
      alt: `${t("edit-group")}`,
      display: isHost
    },
  ];

  return (
    <Flex direction="row">
      <Box
        position="fixed"
        height="100vh"
        paddingTop={9}
        backgroundColor="var(--CLEAR-BACKGROUND)"
        zIndex={1000}
        ref={sidebarRef}
        overflowY="auto"
        pl={4}
      >
        <VStack spacing={4} align="flex-start">
          {groupsData.length > 0 &&
            (isOpen ? (
              <Box>
                <Flex align="center" p={0} mb={0}>
                  <Link
                    as={RouterLink}
                    to={`/group/${groupsData[0].id}`}
                    _hover={{ textDecoration: "none" }}
                  >
                    <Flex direction="row">
                      <Box
                        margin={0}
                        w="25px"
                        h="25px"
                        bg="blue.500"
                        borderRadius="full"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        color="white"
                        fontWeight="bold"
                        fontSize="14px"
                        lineHeight="1"
                        mr={2}
                      >
                        A
                      </Box>
                      <Box className="text-up-select-sidebar">
                        {groupsData[0].name}
                      </Box>
                    </Flex>
                  </Link>
                  {groupsData.length > 1 && (
                    <FontAwesomeIcon
                      icon={openMenu ? faAngleUp : faAngleDown}
                      style={{ marginLeft: "6px", marginTop: "-2px" }}
                      cursor="pointer"
                      onClick={() => setOpenMenu(!openMenu)}
                    />
                  )}
                  <Link as={RouterLink} to={`/group-home/${groupsData[0].id}`}>
                    <Image
                      _hover={{ cursor: "pointer" }}
                      ml={5}
                      src={lightMode ? "home.png" : "home_dark.png"}
                      w={5}
                      mr={3}
                    />
                  </Link>
                </Flex>
              </Box>
            ) : (
              <Box>
                <Flex align="center" p={0} mb={0}>
                  <Tooltip
                    label={t("home-group")}
                    aria-label={`home group tooltip`}
                    placement="right"
                  >
                    <Link
                      as={RouterLink}
                      to={`/group-home/${groupsData[0].id}`}
                    >
                      <Image
                        _hover={{ cursor: "pointer" }}
                        src={lightMode ? "home.png" : "home_dark.png"}
                        w={5}
                        ml={1}
                      />
                    </Link>
                  </Tooltip>
                </Flex>
              </Box>
            ))}

          {groupsData.length > 1 && isOpen && openMenu && (
            <Flex
              align="flex-start"
              direction="column"
              margin={0}
              pt={0}
              pb={4}
              gap={2}
              borderBottom="2px #E8E8E8 solid"
              mr={12}
            >
              {groupsData.slice(1).map((group, i) => (
                <Flex key={i} mb={1}>
                  <Link
                    as={RouterLink}
                    to={`/group/${group.id}`}
                    _hover={{ textDecoration: "none" }}
                  >
                    <Flex direction="row" m={0} p={0}>
                      <Box
                        w="26px"
                        h="26px"
                        bg="red.500"
                        borderRadius="full"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        color="white"
                        fontWeight="bold"
                        fontSize="14px"
                        lineHeight="1"
                        mr={1}
                      >
                        G
                      </Box>
                      <Box className="text-up-sidebar" pt="2px">
                        {group.name}
                      </Box>
                    </Flex>
                  </Link>
                </Flex>
              ))}
            </Flex>
          )}
          {linkItems.map((link, i) =>
            isOpen ? (
              <>
              {link.display && <Link as={RouterLink} to={link.link} className="link" key={i}>
                <Flex align="center">
                  <Image
                    src={lightMode ? link.icon : link.iconDarkMode}
                    className="image"
                    alt={link.alt}
                    boxSize="24px"
                    mr={2}
                  />
                  <Box className="text-up-sidebar">{link.name}</Box>
                </Flex>
              </Link>}
              </>
            ) : (
              <>
              {link.display && 
              <Tooltip
                label={link.name}
                aria-label={`${link.name} tooltip`}
                key={i}
                placement="right"
              >
                <Link
                  as={RouterLink}
                  to={link.link}
                  className="link"
                  _hover={{ textDecoration: "none" }}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <Image
                    src={lightMode ? link.icon : link.iconDarkMode}
                    className="image"
                    alt={link.alt}
                    boxSize="24px"
                  />
                </Link>
              </Tooltip>}
              </>

            )
          )}
        </VStack>
      </Box>
      <div style={{ width: sidebarWidth }}></div>
    </Flex>
  );
}
