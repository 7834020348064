import React, { useState } from "react";
import {
	PaymentElement,
	useStripe,
	useElements,
} from "@stripe/react-stripe-js";
import {
	Box,
	Button,
	Heading,
	Text,
	Spinner,
	Container,
	Card,
	CardBody,
} from "@chakra-ui/react";

const PaymentForm = () => {
	const stripe = useStripe();
	const elements = useElements();

	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!stripe || !elements) return;

		setIsLoading(true);
		setMessage("Payment in Progress");

		const resp = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: `${window.location.protocol}//${window.location.hostname}:3000`
			},
		});

		if (resp.error) setMessage("Some Error Occurred !!");
		setIsLoading(false);
	};

	return (
		<Container maxW="container.md" centerContent>
			<form onSubmit={handleSubmit}>
				<Card w="full" bg="gray.100" shadow="lg" borderRadius="lg">
					<CardBody p={6}>
						<Heading as="h1" size="lg" textAlign="center" mb={4}>
							Complete your payment here!
						</Heading>
						<Box mb={4}>
							<PaymentElement />
						</Box>
						<Button
							colorScheme="blue"
							size="lg"
							width="full"
							isLoading={isLoading}
							disabled={isLoading || !stripe || !elements}
							type="submit"
							mt={6}
						>
							{isLoading ? <Spinner /> : "Pay now"}
						</Button>
						{message && (
							<Text mt={4} color="red.500" textAlign="center">
								{message}
							</Text>
						)}
					</CardBody>
				</Card>
			</form>
		</Container>
	);
};

export default PaymentForm;
