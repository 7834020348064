import { useReducer } from "react";
import { paymentsInitState, paymentsReducer } from "features/payments/reducer";
import axios from "axios";

export default function useCurrentPayments() {
  const [state, dispatch] = useReducer(paymentsReducer, paymentsInitState);

  const fetchPayments = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_STRIPE_BACKEND_URL}/user/${id}`
      );

      const data = response.data;
      const { membershipDate, subscription, sharesAmount, totalInvestment } = data;

      dispatch({
        type: "SET_SHARES_AMOUNT",
        payload: sharesAmount,
      });
      dispatch({
        type: "SET_SUBSCRIPTION",
        payload: subscription,
      });
      dispatch({
        type: "SET_MEMBERSHIP_DATE",
        payload: membershipDate,
      });
      dispatch({
        type: "SET_TOTAL_INVESTMENT",
        payload: totalInvestment,
      });
    } catch (error) {
      throw error;
    }
  };

  return {
    ...state,
    fetchPayments,
  };
}
